import EnterpriseCustomers from '$lib/enterprise-customers';
import { Logger } from './utils';

export async function openPlaid(
	organizationAuctionAccessId: string,
	organizationId: string,
	accessToken?: string,
	storePublicToken = true
): Promise<boolean> {
	const linkToken = await EnterpriseCustomers.getLinkToken(accessToken);
	const res = await new Promise<{ publicToken: string; metadata: unknown }>((res, rej) => {
		const plaid = window.Plaid.create({
			token: linkToken,
			clientName: '',
			product: ['auth'],
			key: '',
			env: undefined as any,
			onSuccess: (publicToken: string, metadata: unknown) => {
				res({ publicToken, metadata });
			},
			onLoad: () => Logger.log('[PLAID] onLoad'),
			onExit: () => {
				Logger.log('[PLAID] onExit');
				rej();
			},
			onEvent: () => Logger.log('[PLAID] onEvent'),
		});
		plaid.open();
	});
	if (storePublicToken) {
		await EnterpriseCustomers.storePublicToken(
			res.publicToken,
			organizationId,
			organizationAuctionAccessId,
			res.metadata
		)
			.then(() => {
				return true;
			})
			.catch((err) => {
				let text = 'failed to exchange public token';
				if (err?.status == '409') {
					text = 'this account is already pending validation';
				}
				Logger.error(text, err);
				throw err;
			});
	}
	return false;
}
