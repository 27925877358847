<script context="module">
	export type DropdownOption = {
		id?: string;
		text?: string;
	};
</script>

<script lang="ts">
	export let labelText: string;
	export let options: DropdownOption[];
	export let dropdownId: string;
	export let selectedId: string;
</script>

{#if labelText}
	<label class="flex font-medium" for={dropdownId}>{labelText}</label>
{/if}
{#if options}
	<select id={dropdownId} class="dropdown" data-test={dropdownId} bind:value={selectedId}>
		{#if !selectedId}
			<option disabled selected hidden value={'waiting'}>Select</option>
		{/if}
		{#each options as Option}
			<option value={Option.id}>{Option.text}</option>
		{/each}
	</select>
{/if}

<style lang="postcss">
	.dropdown {
		@apply w-full pr-16 pl-2 h-10 border rounded;
	}
</style>
