<script lang="ts">
	import { buildSetupStores, getSetupContext, SetupContext } from '$lib/setup-utils';
	import { isOwner } from '$lib/utils';
	import { onMount, setContext } from 'svelte';

	import DealerSelect from './DealerSelect.svelte';
	import { BusinessUnit, RepInformation } from 'ets-clients/dist/customers/v1';
	import EnterpriseCustomers from '$lib/enterprise-customers';
	import Icon from '$components/shared/Icon.svelte';
	import { formatError, track } from '$lib/analytics';
	import { hasAuctionAccess } from '$lib/global-stores';
	import AddPayment from './AddPayment.svelte';
	import Summary from './Summary.svelte';
	import RegistrationUtils from '$lib/registration-utils';

	setContext(SetupContext, buildSetupStores());

	let {
		selectedDealer,
		selectedMarketplaces,
		auctionAccessId,
		contactPhoneNumber,
		repInfo,
		currentReg,
		reloadablePaymentMethods,
		reloadableRepInfo,
		missingPrimaryAddress,
		docusignUrl,
	} = getSetupContext();

	type SetupStep = 'dealerSelect' | 'addPayment' | 'done';

	let loadingPage = true;

	$: step = 'dealerSelect' as SetupStep;

	$: isOwnerOrOfficer = isOwner($selectedDealer?.role ?? '');

	onMount(() => {
		getRepInfo();
	});

	function getRepInfo() {
		return $reloadableRepInfo
			.then((res) => {
				repInfo.set(res);
				fillExistingRegistration(res);
			})
			.catch((err) => {
				console.error(err);
			})
			.finally(() => {
				loadingPage = false;
			});
	}

	async function fillExistingRegistration(repInfo: RepInformation) {
		if (repInfo.existingRegistrations?.find((f) => !f.completed)) {
			currentReg.set(repInfo.existingRegistrations?.find((f) => !f.completed) ?? {});
		}
		const currentOrg =
			repInfo.organizations?.find(
				(f) => f.auctionAccessId == $currentReg?.organizationAuctionAccessId
			) ?? {};

		hasAuctionAccess.set(repInfo?.auctionAccessId != undefined);
		if ($currentReg) {
			selectedDealer.set(currentOrg);
			auctionAccessId.set(repInfo.auctionAccessId ?? '');
			reloadablePaymentMethods.reload();

			step = 'addPayment';
		} else {
			track('Began Setup flow');
		}
	}

	$: {
		track('Setup step viewed', {
			step: step,
		});
	}

	function next() {
		switch (step) {
			case 'dealerSelect':
				submit(!isOwnerOrOfficer);
				break;
			case 'addPayment':
				submit(true);
				break;
		}
	}

	function submit(completed = false) {
		loadingPage = true;
		let BUs: BusinessUnit[] = [BusinessUnit.AdesaCom, BusinessUnit.TradeRev, BusinessUnit.AdesaCa];

		EnterpriseCustomers.registerRep(
			BUs,
			$selectedDealer?.auctionAccessId ?? '',
			$auctionAccessId,
			$selectedMarketplaces.sell,
			$contactPhoneNumber,
			completed,
			$currentReg?.requestId
		)
			.then((res) => {
				track('Save registration');
				missingPrimaryAddress.set(res.missingPrimaryAddress ?? true);
				docusignUrl.set(res.documentUrl ?? '');
				getRepInfo().then(() => {
					if (completed) {
						step = 'done';
					}
					loadingPage = false;
				});
			})
			.catch((err) =>
				track('Failed to save registration', {
					error: formatError(err),
				})
			);
	}
</script>

{#if loadingPage}
	<Icon icon="loading-spinner" />
{:else}
	<img src={RegistrationUtils.openlaneLogoImage()} class="w-[291px] mx-auto mb-14" alt="openlane" />
	{#if step === 'dealerSelect'}
		<DealerSelect on:next={next} />
	{/if}
	{#if step === 'addPayment'}
		<AddPayment on:next={next} />
	{/if}
	{#if step === 'done'}
		<Summary />
	{/if}
{/if}
