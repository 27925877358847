<script lang="ts">
	import Icon from './Icon.svelte';
	import Link from './Link.svelte';
	import { getModalContext } from './Modal.svelte';
	export let title: string;
	const { close } = getModalContext();
</script>

<div class="text-[18px] font-bold flex">
	<div class="flex my-3">{title}</div>
	<div class="flex ml-auto">
		<Link on:click={() => close()}>
			<Icon icon="close" />
		</Link>
	</div>
</div>
