<script lang="ts">
	import RegistrationsRoot from './RegistrationsRoot.svelte';
	import { isLoading } from 'svelte-i18n';
</script>

<main class="flex mt-24">
	{#if !$isLoading}
		<RegistrationsRoot />
	{/if}
</main>

<style>
	main {
		text-align: center;
		padding: 1em;
		margin: 0 auto;
		background-image: url('../public/images/OpenlaneWheel.svg');
		background-repeat: no-repeat;
		background-size: 50%;
		background-position: 100% 0%;
		height: 100vh;
	}

	@media (min-width: 375px) {
		main {
			max-width: none;
		}
	}
</style>
