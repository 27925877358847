<script lang="ts">
	import { track } from '$lib/analytics';
	import { createEventDispatcher } from 'svelte';
	import { _ } from 'svelte-i18n';
	import type { Email } from './Registration.svelte';

	export let email: Email;

	const dispatch = createEventDispatcher();

	function sendAgain() {
		track('Send registration email again');
		dispatch('createId');
	}
</script>

<div
	class="flex flex-col border border-[#d7d7d7] rounded w-[640px] min-w-[640px] min-h-[223px] bg-white p-6"
>
	<div class="flex text-[18px] text-left mb-6 mt-3 font-bold">
		{$_('email-sent.weve-sent', { values: { emailAddress: email.emailAddress } })}
	</div>
	<div class="flex mb-6 text-[15px]">
		{$_('email-sent.to-continue')}
	</div>
	<div class="flex text-muted text-sm">
		{$_('email-sent.didnt-get-a-confirmation-email')} &nbsp;
		<div class="text-primary cursor-pointer" on:click={() => sendAgain()}>
			{$_('email-sent.send-again')}
		</div>
	</div>
</div>
