import { getFromAnyStorage } from '$lib/utils';
import {
	register,
	init,
	getLocaleFromNavigator,
	getLocaleFromQueryString,
	locale,
} from 'svelte-i18n';

register('en', () => import('./locales/en.json'));
register('fr', () => import('./locales/fr.json'));

const getInitialLocale = () => {
	const queryStringLang = getLocaleFromQueryString('lang');
	const lang = queryStringLang ?? getFromAnyStorage('user-locale') ?? getLocaleFromNavigator();
	if (lang) {
		saveLocale(lang);
		if (queryStringLang) {
			const url = new URL(window.location.toString());
			url.searchParams.delete('lang');
			history.replaceState(null, '', url.toString());
		}
	}
	return lang;
};

export const getCurrentLocale = (locale: string | null | undefined) => {
	return locale?.includes('en') ? 'en' : 'fr';
};
export const setCurrentLocale = (newLocale: string) => {
	locale.set(newLocale);
	saveLocale(newLocale);
	window.location.reload();
};
const saveLocale = (lang: string) => {
	localStorage.setItem('user-locale', lang);
};

init({
	fallbackLocale: 'en',
	warnOnMissingMessages: true,
	initialLocale: getInitialLocale(),
});
