import { getContext } from 'svelte';
import { writable } from 'svelte/store';

export default class RegistrationUtils {
	static registrationLandingImage(): string {
		return `images/EReg-Landing-image.jpg`;
	}

	static adesaLogoImage(): string {
		return `images/adesa.svg`;
	}

	static openlaneLogoImage(): string {
		return 'images/Openlane.svg';
	}

	static adesaIdLogoImage(): string {
		return `images/adesaidlogo.svg`;
	}

	static traderevLogoImage(): string {
		return `images/traderev.svg`;
	}
	static infoImage(): string {
		return `images/info.svg`;
	}
	static loginImage(): string {
		return `images/login.svg`;
	}
	static docuSignLogoImage(): string {
		return `images/DocuSign.png`;
	}
	static plaidLogoImage(): string {
		return `images/Plaid.png`;
	}
}

export function buildRegistrationStores() {
	const alreadyExists = writable<boolean>(false);
	return {
		alreadyExists,
	};
}

export const RegistrationContext = {};
export function getRegistrationContext(): ReturnType<typeof buildRegistrationStores> {
	return getContext(RegistrationContext);
}
