<script lang="ts">
	import { fade } from 'svelte/transition';
	import { _ } from 'svelte-i18n';

	export let actions: Partial<Record<string, string>>;
	export let handle: (action: string) => Promise<any>;
	export let forOption: string;

	let isVisible = false;
	let loading: string[] = [];

	function startLoading(actionId: string) {
		loading = [...loading, actionId];
		handle(actionId).finally(() => doneLoading(actionId));
	}
	function doneLoading(actionId: string) {
		loading = loading.filter((it) => it != actionId);
		if (!loading.length) isVisible = false;
	}
</script>

<svelte:window on:click={() => (isVisible = false)} />

{#if Object.entries(actions).length}
	<div class="text-primary my-auto" data-test="actions-{forOption}">
		<div
			class="relative select-none"
			on:click|stopPropagation
			on:mouseenter={() => (isVisible = true)}
			on:mouseleave={() => (isVisible = false)}
		>
			<div class="flex items-center p-2 pb-0 cursor-pointer">
				{$_('shared.actions')}
			</div>
			{#if isVisible}
				<div class="dropdown-list" transition:fade={{ duration: 100 }}>
					{#each Object.entries(actions) as [actionId, actionName]}
						<div
							class="dropdown-item"
							data-test={`action-${actionId}`}
							on:click={() => {
								if (loading.includes(actionId)) return;
								startLoading(actionId);
							}}
						>
							{#if loading.includes(actionId)}
								Loading...
							{:else}
								{actionName}
							{/if}
						</div>
					{/each}
				</div>
			{/if}
		</div>
	</div>
{/if}

<style lang="postcss">
	.dropdown-list {
		@apply absolute bg-white shadow text-black right-0 z-10;
		min-width: 12rem;
	}
	.dropdown-item {
		@apply p-4 cursor-pointer;
		&:hover {
			@apply bg-grey-4;
		}
	}
</style>
