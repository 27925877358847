import { getFromAnyStorage } from '$lib/utils';
import * as v1 from 'ets-clients/dist/customers/v1';
import * as v2 from 'ets-clients/dist/customers/v2';
import jwt_decode, { JwtPayload } from 'jwt-decode';
export type EcsProfile = v1.ProfileDetails;
export type EcsAddress = v1.AddressResponse;

interface karIdJwtPayload {
	email?: string;
	auction_access_id?: string;
	cc_master_id?: string;
}

class karIdTokenClaims implements JwtPayload, karIdJwtPayload {
	iss?: string;
	sub?: string;
	aud?: string[] | string;
	exp?: number;
	nbf?: number;
	iat?: number;
	jti?: string;
	email?: string;
	auction_access_id?: string;
	cc_master_id?: string | undefined;
}

export default class EnterpriseCustomers {
	static region: v1.Region = v1.Region.Us;

	static get accessToken(): string {
		const oktaStorage = getFromAnyStorage('okta-token-storage') ?? 'null';

		const json = JSON.parse(oktaStorage);
		return json != null ? json.accessToken?.accessToken : '';
	}

	static validateToken() {
		if (!this.accessToken && !TEST) {
			throw new Error('failed to acquire token');
		}
	}

	static get email(): string {
		this.validateToken();
		return TEST ? '' : jwt_decode<karIdTokenClaims>(this.accessToken).sub ?? '';
	}

	static get auctionAccessId(): string {
		this.validateToken();
		return TEST ? '' : jwt_decode<karIdTokenClaims>(this.accessToken).auction_access_id ?? '';
	}

	static get ccMasterId(): string {
		this.validateToken();
		return TEST ? '' : jwt_decode<karIdTokenClaims>(this.accessToken).cc_master_id ?? '';
	}

	static get basePath() {
		return ECS_HOST ?? 'http://localhost:5003';
	}

	static async getProfile(auctionAccessIds: string[] | undefined): Promise<v1.ProfileDetails> {
		return await new v1.ProfileApi(this.config()).apiV1ProfileGet({ auctionAccessIds });
	}

	static async verifyProfile(
		lastFour: string,
		auctionAccessId: string
	): Promise<v1.VerificationResponse> {
		return await new v1.RepsApi(this.anonymousConfig()).apiV1RepsVerifyPost({
			verifyRepRequest: { lastFour: lastFour, auctionAccessId: auctionAccessId },
		});
	}

	static async verifyOrgProfile(
		lastFour: string,
		auctionAccessId: string,
		phoneNumber: string
	): Promise<v1.OrganizationInformation> {
		return await new v1.OrganizationsApi(this.config()).apiV1OrganizationsVerifyPost({
			verifyOrganizationRequest: {
				lastFour: lastFour,
				auctionAccessId: auctionAccessId,
				phoneNumber: phoneNumber,
			},
		});
	}

	static async createId(
		email: string,
		firstName: string,
		lastName: string,
		auctionAccessId: string
	): Promise<v1.UserRegistrationResponse> {
		return await new v1.AuthApi(this.anonymousConfig()).apiV1AuthPost({
			userRegistrationRequest: {
				email: email,
				mfa: false,
				sendEmail: true,
				firstName: firstName,
				lastName: lastName,
				locale: 'en',
				forwardTo: `${REDIRECT_BASE_URI}/setup`,
				countryCode: 'US',
				auctionAccessId: auctionAccessId,
			},
		});
	}

	static async createPaymentMethod(request: v1.PaymentMethodRequest, organizationId: string) {
		return await new v1.PaymentMethodsApi(this.config()).apiV1PaymentMethodsUserOrganizationIdPost({
			paymentMethodRequest: request,
			organizationId,
		});
	}

	static async getRepInfo(): Promise<v1.RepInformation> {
		return await new v1.RepsApi(this.config()).apiV1RepsOrgsPost();
	}

	static async registerRep(
		bus: v1.BusinessUnit[],
		orgAaId: string,
		repAaId: string,
		sellingAccess: boolean,
		phoneNumber: string,
		completed: boolean,
		requestId?: string
	): Promise<v2.RegistrationResponse> {
		return await new v2.RegisterApi(this.v2Config()).apiV2RegisterPut({
			registerRepRequest: {
				businessUnits: bus,
				organizationAuctionAccessId: orgAaId,
				repAuctionAccessId: repAaId,
				sellingAccess: sellingAccess,
				phoneNumber: phoneNumber,
				completed: completed,
				requestId: requestId,
				enrolledPrograms: [v1.ProgramEnrollment.Ca],
				redirectUrl: `${REDIRECT_BASE_URI}/explore`,
			},
		});
	}

	static async inviteRep(
		email: string,
		organizationId: string,
		roles: v1.Role[],
		firstName: string,
		lastName: string
	): Promise<void> {
		return await new v1.OrganizationsApi(this.config()).apiV1OrganizationsInviteRepPost({
			repInviteRequest: {
				email: email,
				organizationId: organizationId,
				roles: roles,
				firstName: firstName,
				lastName: lastName,
			},
		});
	}

	static async updateRepRoles(
		emailAddress: string,
		orgId: string,
		roles: v1.Role[],
		repId?: string
	): Promise<void> {
		return await new v1.OrganizationsApi(this.config()).apiV1OrganizationsUpdateRepRolesPost({
			updateRepRolesRequest: {
				emailAddress,
				orgId,
				roles: roles,
				repId,
			},
		});
	}

	static async revokeInvite(
		email: string,
		organizationId: string,
		roles: v1.Role[],
		firstName: string,
		lastName: string
	): Promise<void> {
		return await new v1.OrganizationsApi(this.config()).apiV1OrganizationsInviteRepPost({
			repInviteRequest: {
				email: email,
				organizationId: organizationId,
				roles: roles,
				firstName: firstName,
				lastName: lastName,
			},
		});
	}

	static async getInvitedReps(orgID: string): Promise<v1.InvitedRep[]> {
		return await new v1.OrganizationsApi(this.config()).apiV1OrganizationsGetInvitedRepsGet({
			orgId: orgID,
		});
	}

	static async getReps(orgId: string): Promise<v1.Rep[]> {
		return await new v1.OrganizationsApi(this.config()).apiV1OrganizationsGetRepsGet({
			orgId: orgId,
		});
	}

	// getReps(orgSpecifier: OrganizationSpecifier) {
	// 	if (orgSpecifier.ecsId) {
	// 		return trackPromise(
	// 			organizationsApi.apiV1OrganizationsGetRepsGet({
	// 				orgId: orgSpecifier.ecsId,
	// 			}),
	// 			'Get all reps'
	// 		);
	// 	} else if (orgSpecifier.auctionAccessId) {
	// 		return Promise.resolve([]);
	// 	} else {
	// 		throw 'Failed to get reps, no organization ID specified.';
	// 	}
	// },

	static async getLinkToken(accessToken?: string): Promise<string> {
		return await new v1.PaymentMethodsApi(this.config())
			.apiV1PaymentMethodsPlaidLinkTokenPost({
				linkTokenRequest: {
					lang: v1.Language.En,
					countryCodes: [EnterpriseCustomers.region],
					accessToken,
				},
			})
			.then((r) => r.token ?? '');
	}

	static async storePublicToken(
		publicToken: string,
		organizationId: string,
		auctionAccessId: string,
		metadata: unknown
	): Promise<void> {
		await new v1.PaymentMethodsApi(this.config()).apiV1PaymentMethodsPlaidPublicTokenPost({
			addPlaidAchAccountRequest: {
				organizationId,
				organizationAuctionAccessId: auctionAccessId,
				publicToken,
				metadata: metadata as any,
			},
		});
	}

	static async reAuth(paymentAccountId: string) {
		await new v1.PaymentMethodsApi(
			this.config()
		).apiV1PaymentMethodsPlaidReauthPaymentAccountIdPost({
			paymentAccountId,
		});
	}

	static async removePaymentMethod(paymentMethodId: string) {
		return await new v1.PaymentMethodsApi(this.config()).apiV1PaymentMethodsPaymentMethodIdDelete({
			paymentMethodId,
		});
	}

	static async getPendingPaymentMethods(auctionAccessId: string) {
		return await new v1.PaymentMethodsApi(
			this.config()
		).apiV1PaymentMethodsPendingAuctionAccessAaIdGet({
			aaId: auctionAccessId,
		});
	}

	static async getPaymentMethods(auctionAccessId: string) {
		return await new v1.PaymentMethodsApi(this.config()).apiV1PaymentMethodsAuctionAccessAaIdGet({
			aaId: auctionAccessId,
		});
	}

	private static config() {
		this.validateToken();
		return new v1.Configuration({
			accessToken: `Bearer ${this.accessToken}`,
			basePath: this.basePath,
		});
	}

	private static v2Config() {
		this.validateToken();
		return new v2.Configuration({
			accessToken: `Bearer ${this.accessToken}`,
			basePath: this.basePath,
		});
	}

	private static anonymousConfig() {
		return new v1.Configuration({
			basePath: this.basePath,
		});
	}
}
