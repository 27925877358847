import type { OktaAuth } from '@okta/okta-auth-js';
import { getContext, setContext } from 'svelte';
import type { Writable } from 'svelte/store';

const AUTH = {};
interface AuthContext {
	isAuthenticated: Writable<boolean>;
	authClient: Writable<OktaAuth>;
}

export function getAuthContext() {
	return getContext<AuthContext>(AUTH);
}

export function setAuthContext(context: AuthContext) {
	return setContext(AUTH, context);
}
