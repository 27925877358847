<script lang="ts">
	import { track } from '$lib/analytics';

	import type { IconType } from './Icon.svelte';
	import Icon from './Icon.svelte';

	export let text = '';
	export let dataTest: string | undefined = undefined;
	export let disabled = false;
	export let fullWidth = false;
	export let inverse = false;
	export let type = 'button';
	export let icon: IconType | undefined = undefined;
	export let trackSegmentAction: string | undefined = undefined;

	if (trackSegmentAction) {
		track(trackSegmentAction);
	}
</script>

<div class="flex" class:fullWidth>
	<button
		class:disabled
		class:fullWidth
		class:inverse
		on:click
		data-test={dataTest}
		{type}
		{disabled}
	>
		<div class="flex items-center justify-center font-bold">
			{#if text || icon}
				<div class="flex items-center text-lg font-bold justify-center">
					{#if text}
						<div>{text}</div>
					{/if}
					{#if icon}
						<Icon extraClass="ml-2" {icon} />
					{/if}
				</div>
			{:else}
				<slot />
			{/if}
		</div>
	</button>
</div>

<style lang="postcss">
	button {
		@apply block font-bold px-4 py-2 text-center text-sm rounded-md border cursor-pointer bg-primary-button text-white;
		&:not(.flat) {
			min-height: 2.75rem;
		}

		&.disabled {
			@apply opacity-50 cursor-not-allowed;
		}

		&.fullWidth {
			@apply w-full;
		}
		&.inverse {
			@apply border border-2 rounded border-black bg-white text-black;
		}
	}

	div {
		&.fullWidth {
			@apply w-full;
		}
	}
</style>
