export function getFromAnyStorage(key: string) {
	return sessionStorage.getItem(key) ?? localStorage.getItem(key);
}

export class Logger {
	private static tag = '[registration]';
	static debug(...args: any[]) {
		console.debug(this.tag, ...args);
	}
	static log(...args: any[]) {
		console.log(this.tag, ...args);
	}
	static warn(...args: any[]) {
		console.warn(this.tag, ...args);
	}
	static error(...args: any[]) {
		console.error(this.tag, ...args);
	}
}

export type FormError = {
	field: string;
	error?: string;
};

export enum IssueType {
	ViewPermissionOnly,
	Blocking,
}

export enum DealershipRole {
	Owner = 'Owner of',
	Officer = 'Officer of',
	Rep = 'Rep for',
}

const ViewPermissionOnlyIssues = [
	'Admin Hold - Buy',
	'Dealer Authorization Letter Not Scanned',
	'Dealer Authorization Letter Rejected',
	'Photo ID expired',
	'Photo ID not scanned',
	'Representative is in KO Book',
];

const BlockingIssues = [
	'Application Not Scanned',
	'Application Rejected',
	'Duplicate',
	'ID verification warning',
	'In Red Flags violation',
	'In Violation of Autotec Policies',
	'Individual Authorization Letter Rejected',
	'Individual Authorization Not Scanned',
	'Individual Authorization Rejected',
	'Invalid Information',
	'Locked per Owner/Officer',
	'Membership Fee Due',
	'Photo ID Rejected',
];

export const ActivityKeys = {
	SignUp: 'Sign up',
	TakeMeToOpenlane: 'Take me to OPENLANE',
	ContinueToSetup: 'Continue to setup',
	ContactUs: 'Contact us',
	DocusignLink: 'Docusign Link',
	NoAuctionAccess: 'No auction access link',
};

export function HasIssueOfType(issues: string[]): IssueType[] {
	const issueTypesInList = [];
	if (issues.some((r) => ViewPermissionOnlyIssues.includes(r))) {
		issueTypesInList.push(IssueType.ViewPermissionOnly);
	}
	if (issues.some((r) => BlockingIssues.includes(r))) {
		issueTypesInList.push(IssueType.Blocking);
	}
	return issueTypesInList;
}

export function HasViewPermissionOnlyIssue(issues: string[]): boolean {
	return HasIssueOfType(issues ?? []).includes(IssueType.ViewPermissionOnly);
}

export function hasBlockingIssues(issues: string[]): boolean {
	return HasIssueOfType(issues ?? []).includes(IssueType.Blocking);
}

export function unmaskPhoneNumber(phoneNumber: string) {
	return phoneNumber.replace(/\D/g, '');
}

export function isOwner(role: string) {
	return !![DealershipRole.Owner, DealershipRole.Officer].find((t) => t === role);
}

export function maskPhoneNumber(phoneNumber: string) {
	let maskedNumber: string = phoneNumber.slice(0, 3);
	if (phoneNumber.length > 3) {
		maskedNumber = maskedNumber + '-' + phoneNumber.slice(3, 6);
	}
	if (phoneNumber.length > 6) {
		maskedNumber = maskedNumber + '-' + phoneNumber.slice(6, 10);
	}
	return maskedNumber;
}
