import type { RepInformation } from 'ets-clients/dist/customers/v1';
import { Readable, Updater, get, writable } from 'svelte/store';

export const isMobile = writable(false);

export const hasAuctionAccess = writable(true);

export const repInfo = writable<RepInformation>();

type WithFormAttributes<T> = T & {
	dirty: boolean;
	errors: Partial<Record<string, string>>;
	loading: boolean;
	runValidations: boolean;
};
export interface FormStore<T> extends Readable<WithFormAttributes<T>> {
	submit: () => Promise<void>;
	update: (updater: Updater<T>) => void;
	set: (val: T) => void;
}
interface FormStoreArgs<T> {
	initialValue: T;
	validate?(value: T): Partial<Record<string, string>>;
	submit: (value: T) => Promise<void>;
}
export function formStore<T extends Record<string, unknown>>({
	initialValue,
	submit,
	validate = () => ({}),
}: FormStoreArgs<T>): FormStore<T> {
	const store = writable<WithFormAttributes<T>>({
		...initialValue,
		dirty: false,
		errors: {},
		loading: false,
		runValidations: false,
	});
	function update(updater: Updater<T>) {
		store.update((val) => {
			const newVal = { ...updater(val), dirty: true };
			return {
				...newVal,
				loading: val.loading,
				runValidations: val.runValidations,
				errors: val.runValidations ? validate(newVal) : {},
			};
		});
	}
	return {
		subscribe: store.subscribe,
		update,
		set: (val: T) => {
			update(() => val);
		},
		submit: async () => {
			const errors = validate(get(store));
			store.update((s) => ({ ...s, errors, runValidations: true }));
			if (Object.entries(errors).length) {
				return;
			}
			store.update((s) => ({ ...s, loading: true }));
			await submit(get(store)).catch((err) => console.error('Form submit failed:', err));
			store.update((s) => ({ ...s, dirty: false, loading: false }));
		},
	};
}
