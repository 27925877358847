<script lang="ts">
	import Divider from '$components/shared/Divider.svelte';

	import Icon from '$components/shared/Icon.svelte';
	import TitledElement from '$components/shared/TitledElement.svelte';
	import Button from '$components/shared/Button.svelte';
	import { getSetupContext } from '$lib/setup-utils';
	import { _, locale } from 'svelte-i18n';
	import EnterpriseCustomers from '$lib/enterprise-customers';
	import Banner from '$components/shared/Banner.svelte';
	import AddUserModal from '$components/shared/AddUserModal.svelte';
	import Actions from '$components/shared/Actions.svelte';
	import { track } from '$lib/analytics';
	import { ActivityKeys, isOwner } from '$lib/utils';
	import { onMount } from 'svelte';
	import { goTo } from 'src/router/Router.svelte';
	import { getCurrentLocale } from 'src/i18n';

	let modal: AddUserModal;

	const { reloadableReps, currentReg, selectedDealer, missingPrimaryAddress, docusignUrl } =
		getSetupContext();

	$: actions = { cancel: 'Cancel invitation' };

	onMount(() => {
		if (isOwner($selectedDealer.role ?? '')) {
			reloadableReps.reload();
		}
	});

	async function revokeInvite(email: string, repId?: string) {
		await EnterpriseCustomers.updateRepRoles(email, $currentReg.organizationId!, [], repId)
			.then(() => {
				reloadableReps.reload();
			})
			.catch((err) => {
				console.error(err);
				track('failed to revoke invitation', {
					rep: email,
					error: err,
				});
			});
	}
</script>

<div class="flex flex-col">
	{#if DOCUSIGN_UI}
		<div class="flex flex-col">
			{#if !missingPrimaryAddress}
				<div class="mb-8 bg-white">
					<Banner kind="success">
						{$_('summary.you-can-now-view')}
					</Banner>
				</div>
			{/if}
			<TitledElement title={$_('summary.youre-almost-there')} testData="summary">
				<div class="p-6 leading-[22px]" slot="content">
					<div class="flex flex-col mb-6">
						<p class="flex">
							{$_('summary.start-buying-and-selling-faster')}
						</p>
						<ul class="list-disc list-inside">
							<li>
								{$_('summary.dealership-license')}
							</li>
							<li>
								{$_('summary.business-banking-details')}
							</li>
							<li>
								{$_('summary.floor-plan-details')}
							</li>
							<li>
								{$_('summary.business-tax-details')}
							</li>
						</ul>

						<div class="flex mt-7.5">
							<Button
								fullWidth
								icon="external"
								dataTest="take-me-to-openlane"
								on:click={() => goTo($docusignUrl, ActivityKeys.DocusignLink)}
								text={$_('summary.finish-registration')}
							/>
						</div>

						<div class="flex mt-7.5">
							{$_('summary.youll-also-receive-an-email-to-this-link')}
						</div>
					</div>
				</div>
			</TitledElement>
		</div>
	{:else}
		<div class="flex">
			<TitledElement title={$_('summary.you-are-done')} testData="summary">
				<div class="p-6" slot="content">
					<div class="flex flex-col">
						{#if !missingPrimaryAddress}
							<div class="mb-6">
								<Banner kind="success">
									{$_('summary.you-can-now-view')}
								</Banner>
							</div>
						{/if}
						<div class="flex">
							<div class="my-auto ml-2">
								{$_('summary.a-representative-will-reach-out')}
								<a
									style="color:#1a47e8 !important"
									href={DOCUMENT_URI}
									rel="noopener noreferrer"
									target="_blank"
									>{$_('summary.download-the-forms')}
									<div class="inline-block">
										<Icon icon="external-blue" />
									</div>
								</a>
							</div>
						</div>
					</div>
				</div>
			</TitledElement>
		</div>
		<div class="mt-6">
			<TitledElement infoBorder={false} title={$_('summary.next-steps')}>
				<div class="pt-4 pb-6 px-6" slot="content">
					{#if isOwner($selectedDealer.role ?? '')}
						<div class="flex mb-4">
							<div class="flex my-auto">
								{$_('summary.invite-your-team-members')}
							</div>
							<div class="flex ml-auto">
								<Button
									inverse
									text={$_('summary.add-user')}
									dataTest="add-user-button"
									on:click={() => modal.open()}
								/>
							</div>
						</div>
						{#await $reloadableReps}
							<Icon icon="loading-spinner" />
						{:then reps}
							{#each reps as rep, i}
								{#if rep.email != EnterpriseCustomers.email}
									{#if i != 0}
										<div class="my-3">
											<Divider noPadding />
										</div>
									{/if}
									<div class="flex" data-test={`${rep.firstName}-${rep.lastName}`}>
										<div class="flex flex-col">
											<div class="flex text-lg mb-1">
												{rep.firstName}
												{rep.lastName}
											</div>
											<div class="flex mb-1">
												<div class="text-muted">{$_('summary.email')}:</div>
												&nbsp{rep.email}
											</div>
											<div>{$_('summary.roles')}: {rep.roles?.join(' | ')}</div>
										</div>
										<div class="flex ml-auto mb-auto">
											<Actions
												forOption={rep.email ?? ''}
												{actions}
												handle={async (action) => {
													if (action === 'cancel') {
														return revokeInvite(rep.email ?? '', rep.repId ?? undefined);
													} else throw new Error(`Invalid action: ${action}`);
												}}
											/>
										</div>
									</div>
								{/if}
							{/each}
						{/await}
					{/if}
					<div class="flex my-4">
						<Divider noPadding />
					</div>

					<div class="flex">
						<div class="flex">
							{$_('summary.set-up-another-dealership')}
						</div>
						<div class="flex ml-auto">
							<Button
								inverse
								text={$_('summary.add-dealership')}
								dataTest="add-dealership-button"
								on:click={() => window.location.reload()}
							/>
						</div>
						<AddUserModal getReps={reloadableReps} bind:this={modal} />
					</div>
				</div></TitledElement
			>
		</div>
	{/if}
</div>
