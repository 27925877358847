<script context="module" lang="ts">
	const key = {};
	export interface ModalContext {
		open: () => void;
		close: () => void;
	}
	export function getModalContext(): ModalContext {
		return getContext(key);
	}
	function setModalContext(ctx: ModalContext) {
		setContext(key, ctx);
	}
</script>

<script lang="ts">
	import { getModalProviderContext } from '$components/providers/ModalProvider.svelte';
	import { createEventDispatcher, getContext, setContext } from 'svelte';
	import Portal from 'svelte-portal/src/Portal.svelte';
	const dispatch = createEventDispatcher<{ close: void }>();

	let backgroundEl: HTMLElement;

	function handleKeydown(event: KeyboardEvent) {
		if (event.key === 'Escape') {
			event.preventDefault();
			event.stopPropagation();
			close();
		}
	}
	export let dataTest: string | undefined = undefined;
	export let size: 'small' | 'medium' | 'large' = 'medium';
	export let startOpen = false;
	let isOpen = startOpen;
	export function open() {
		isOpen = true;
	}
	export function close() {
		dispatch('close');
		isOpen = false;
	}
	const modalProviderContext = getModalProviderContext();
	setModalContext({ open, close });
</script>

<svelte:window on:keydown={handleKeydown} />

<div class="contents" on:keypress={() => open()} on:click={() => open()}>
	<slot name="opener" />
</div>

<Portal target=".registration-root">
	<div
		class="bg"
		class:isOpen
		bind:this={backgroundEl}
		on:keypress={(ev) => {
			if (ev.target === backgroundEl) close();
		}}
		on:click={(ev) => {
			if (ev.target === backgroundEl) close();
		}}
	>
		<div class="modal {size}" class:isOpen data-test={dataTest}>
			{#if $$slots.header}
				<header>
					<slot name="header" {close} />
				</header>
			{/if}
			<main>
				<slot {close} />
			</main>
			{#if $$slots.footer}
				<footer>
					<slot name="footer" {close} />
				</footer>
			{/if}
		</div>
	</div>
</Portal>

<style lang="postcss">
	.modal {
		@apply hidden bg-white rounded-lg;
		width: 600px;
		max-width: 90vw;

		&.isOpen {
			display: initial;
		}
	}

	.large {
		@apply w-[1000px];
		@apply overflow-scroll;
	}

	.small {
		@apply w-[367px];
	}

	header {
		@apply p-4 px-8 rounded-t-lg;
	}
	main {
		@apply p-4 px-8 rounded-lg;
		min-height: 100px;
	}
	footer {
		@apply p-4 px-8 rounded-b-lg;
	}

	.bg {
		@apply hidden fixed top-0 left-0 z-50 flex-col items-center justify-center w-screen h-screen;
		&.isOpen {
			@apply flex;
		}
		background: rgba(0, 0, 0, 0.66);
	}
</style>
