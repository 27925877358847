<script lang="ts">
	import { isMobile } from '$lib/global-stores';

	import RegistrationUtils from '$lib/registration-utils';

	export let useOpenlaneHeader = false;
	export let title = '';
	export let testData = '';
	export let infoBorder = true;

	$: mobile = $isMobile;
</script>

<div class="outer text-left" data-test={testData} class:mobile>
	<div class="rounded-t flex" class:infoBorder style="height:70px;">
		<div class="flex">
			{#if useOpenlaneHeader}
				<img src={RegistrationUtils.openlaneLogoImage()} alt="openlane" />
			{:else if title}
				<div class="text-[18px] pl-6 font-bold flex my-auto">
					{title}
				</div>
			{:else}
				<slot name="leftHeader" />
			{/if}
		</div>

		<div class="ml-auto pr-6 mt-auto">
			<slot name="rightHeader" />
		</div>
	</div>
	<slot name="content" />
</div>

<style lang="postcss">
	.outer {
		@apply border border-[#d7d7d7] rounded w-[647px] min-w-[647px] bg-white;
		&.mobile {
			@apply w-[375px] min-w-[375px];
		}
	}
	.infoBorder {
		@apply border-b-[3px] border-info;
	}
</style>
