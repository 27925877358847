<script lang="ts">
	import { _ } from 'svelte-i18n';
	import { openPlaid } from '$lib/plaid';
	import { getSetupContext } from '$lib/setup-utils';
	import RegistrationUtils from '$lib/registration-utils';
	import Button from './Button.svelte';

	const { selectedDealer, reloadablePaymentMethods, currentReg } = getSetupContext();

	async function doPlaid() {
		await openPlaid($selectedDealer.auctionAccessId ?? '', $currentReg.organizationId ?? '').then(
			() => reloadablePaymentMethods.reload()
		);
	}
</script>

<div class="flex">
	<div class="flex my-auto">
		<img src={RegistrationUtils.plaidLogoImage()} class="w-[110px] h-[40px]" alt="Plaid" />
	</div>

	<div class="flex my-auto ml-auto">
		<Button inverse on:click={doPlaid} text={$_('account-options.setup-payments-with-plaid')} />
	</div>
</div>
