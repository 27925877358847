<script lang="ts">
	import Button from '$components/shared/Button.svelte';
	import { _, locale } from 'svelte-i18n';
	import { goTo } from 'src/router/Router.svelte';
	import RegistrationUtils from '$lib/registration-utils';
	import TitledElement from '$components/shared/TitledElement.svelte';
	import { ActivityKeys } from '$lib/utils';
	import Banner from '$components/shared/Banner.svelte';
	import { getCurrentLocale } from 'src/i18n';
	import { SetupContext, buildSetupStores, getSetupContext } from '$lib/setup-utils';
	import { setContext } from 'svelte';

	setContext(SetupContext, buildSetupStores());
	const { reloadableRepInfo } = getSetupContext();
</script>

<img src={RegistrationUtils.openlaneLogoImage()} class="w-[291px] mx-auto mb-14" alt="openlane" />

<div class="bg-white my-8">
	<Banner kind="success" dismissable={false}>
		{$_('explore.weve-received')}
	</Banner>
</div>

<TitledElement title={$_('explore.heres-whats-next')} testData="explore">
	<div slot="content" class="flex flex-col p-6">
		{#await $reloadableRepInfo then repInfo}
			<div class="flex">
				{$_('explore.well-reach-out')}
				{#if repInfo.hasPrimaryAddresses}
					{$_('explore.in-the-meantime')}
				{/if}
			</div>
			{#if repInfo.hasPrimaryAddresses}
				<div class="flex my-7.5">
					<Button
						fullWidth
						dataTest="view-inventory"
						on:click={() =>
							goTo(
								OMP_REDIRECT_URI,
								ActivityKeys.TakeMeToOpenlane,
								true,
								getCurrentLocale($locale)
							)}
						text={$_('summary.take-me-to-openlane')}
						icon="external"
					/>
				</div>
			{:else}
				<div class="flex my-3" />
			{/if}
		{/await}

		<div class="flex flex-col">
			<div class="font-bold">
				{$_('explore.looking-to-sell')}
			</div>

			<div>
				{@html $_('explore.download-sign-send')}
			</div>
		</div>
	</div>
</TitledElement>
