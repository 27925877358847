<script context="module" lang="ts">
	import { getContext, setContext } from 'svelte';
	import { writable } from 'svelte/store';
	import type { Readable } from 'svelte/store';
	import type { EcsProfile } from '$lib/enterprise-customers';
	import EnterpriseCustomers from '$lib/enterprise-customers';
	import { Logger } from '$lib/utils';
	import { _ } from 'svelte-i18n';
	import { getAuthContext } from './contexts';
	export const KEY = {};

	export const getProfileStore = () => getContext(KEY) as Readable<EcsProfile>;
	export const profile = writable<EcsProfile>();
</script>

<script lang="ts">
	const { isAuthenticated } = getAuthContext();
	setContext(KEY, profile);

	let error = false;
	$: if (!$profile && ($isAuthenticated || TEST)) {
		EnterpriseCustomers.getProfile(
			EnterpriseCustomers.auctionAccessId ? [EnterpriseCustomers.auctionAccessId] : undefined
		)
			.then((it) => profile.set(it))
			.catch(() => (error = true));
	}
	$: {
		if ($profile) {
			try {
				analytics.identify(EnterpriseCustomers.email, {
					name: `${$profile.firstName} ${$profile.lastName}`,
					email: $profile.email,
					auctionAccessId: $profile.auctionAccessId,
					organizations: $profile.organizationSummaries?.map((o) => o.auctionAccessId),
				});
			} catch (err) {
				Logger.warn('Failed to analytics.identify due to:', err);
			}
		}
	}
</script>

{#if error}
	<p>An error occurred, please refresh and try again later.</p>
{:else if $profile || !EnterpriseCustomers.accessToken}
	<slot />
{:else}
	<p>{$_('shared.loading')}</p>
{/if}
