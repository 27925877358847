<script lang="ts">
	import EnterpriseCustomers from '$lib/enterprise-customers';
	import { track } from '$lib/analytics';
	import ModalHeader from 'ets-shared-components/src/components/ModalHeader.svelte';
	import { selectedRoles } from '$lib/roles-and-permissions';
	import Input from '$components/shared/Input.svelte';
	import Divider from '$components/shared/Divider.svelte';
	import type { FormError } from '$lib/utils';
	import type { ReloadableStore } from 'ets-shared-components/src/lib/reloadable-store';
	import RoleSelector from '$components/shared/RoleSelector.svelte';
	import type { Rep } from 'ets-clients/dist/customers/v1';
	import Modal from './Modal.svelte';
	import Button from './Button.svelte';
	import { getSetupContext } from '$lib/setup-utils';
	import { isMobile } from '$lib/global-stores';

	export let getReps: ReloadableStore<Rep[]>;

	export async function open() {
		modal.open();
	}

	let modal: Modal;

	const { currentReg } = getSetupContext();

	let loading = false;
	let email = '';
	let verifyEmail = '';
	let firstName = '';
	let lastName = '';
	let errors: FormError[] = [];

	const fields = {
		emailAddress: 'emailAddress',
		verifyEmailAddress: 'verifyEmailAddress',
		firstName: 'firstName',
		lastName: 'lastName',
	};

	let emailRegex = new RegExp(/^[\w.+]+(\[\+\.-\]?\w)*@\w+(\[\.-\]?\w+)*\.[a-z]+$/i);

	function validateEmailAddress() {
		if (email == '') {
			errors.push({ field: fields.emailAddress, error: 'Email address is required' });
		}
		if (email !== undefined && !email?.match(emailRegex)) {
			errors.push({ field: fields.emailAddress, error: 'Email address format incorrect' });
		}
	}

	function validateVerifyEmailAddress() {
		if (verifyEmail != email) {
			errors.push({
				field: fields.emailAddress,
				error: 'Email addresses must match',
			});
		}
	}

	function validate(field?: string) {
		if (!field) {
			validateAll();
		} else {
			validateField(field);
		}
	}

	function validateFirstName() {
		if ((firstName?.length ?? 0) <= 0) {
			errors.push({ field: fields.firstName, error: 'First name is required' });
		} else if ((firstName?.length ?? 0) < 2) {
			errors.push({
				field: fields.firstName,
				error: 'First name must be greater than 2 characters',
			});
		}
	}

	function validateLastName() {
		if ((lastName?.length ?? 0) <= 0) {
			errors.push({ field: fields.lastName, error: 'Last name is required' });
		} else if ((lastName?.length ?? 0) < 2) {
			errors.push({ field: fields.lastName, error: 'Last name must be greater than 2 characters' });
		}
	}

	function validateAll() {
		errors = [];
		validateEmailAddress();
		validateVerifyEmailAddress();
		validateFirstName();
		validateLastName();
	}

	function validateField(field: string) {
		errors = errors.filter((e) => e.field !== field);

		if (field === fields.firstName) {
			validateFirstName();
		}
		if (field === fields.lastName) {
			validateLastName();
		}
		if (field === fields.emailAddress) {
			validateEmailAddress();
		}
	}

	async function submit() {
		loading = true;
		validate();
		if (errors.length === 0 && $currentReg.organizationId) {
			inviteRep();
		}
	}

	async function inviteRep() {
		try {
			await EnterpriseCustomers.inviteRep(
				email,
				$currentReg.organizationId!,
				$selectedRoles,
				firstName,
				lastName
			).then(() => clearData());
		} catch (err: any) {
			track('Failed to invite rep', {
				rep: email,
				error: err,
			});
		} finally {
			loading = false;
			getReps.reload();
			modal.close();
		}
	}

	function clearData() {
		email = '';
		verifyEmail = '';
		firstName = '';
		lastName = '';
		selectedRoles.set([]);
	}
</script>

<Modal size="large" dataTest="add-user-modal" bind:this={modal}>
	<ModalHeader title={'Add user'} />
	<div class:flex-col={$isMobile} class="flex my-4">
		<div class:mr-6={!$isMobile} class:mb-3={$isMobile} class="flex-grow">
			<Input
				showError={!!errors.find((f) => f.field === fields.firstName)}
				errorText={errors.find((f) => f.field === fields.firstName)?.error}
				labelText={'First name'}
				placeholderText={'First'}
				fieldName={fields.firstName}
				bind:value={firstName}
				on:blur={() => validate(fields.firstName)}
			/>
		</div>
		<div class:mr-6={!$isMobile} class:mb-3={$isMobile} class="flex-grow">
			<Input
				showError={!!errors.find((f) => f.field === fields.lastName)}
				errorText={errors.find((f) => f.field === fields.lastName)?.error}
				labelText={'Last name'}
				placeholderText={'Name'}
				fieldName={fields.lastName}
				bind:value={lastName}
				on:blur={() => validate(fields.lastName)}
			/>
		</div>
		<div class:mr-6={!$isMobile} class:mb-3={$isMobile} class="flex-grow">
			<Input
				showError={!!errors.find((f) => f.field === fields.emailAddress)}
				errorText={errors.find((f) => f.field === fields.emailAddress)?.error}
				labelText={'Email address'}
				placeholderText={'First@gmail.com'}
				fieldName={fields.emailAddress}
				bind:value={email}
				on:blur={() => validate(fields.emailAddress)}
			/>
		</div>
		<div class:mb-3={$isMobile} class="flex-grow">
			<Input
				showError={!!errors.find((f) => f.field === fields.verifyEmailAddress)}
				errorText={errors.find((f) => f.field === fields.verifyEmailAddress)?.error}
				labelText={'Verify email'}
				placeholderText={'First@gmail.com'}
				fieldName={fields.verifyEmailAddress}
				bind:value={verifyEmail}
				on:blur={() => validate(fields.verifyEmailAddress)}
			/>
		</div>
	</div>
	<div class="flex mb-4" />
	<Divider noPadding />

	<RoleSelector />

	<div class="mt-8 flex w-full justify-end">
		<Button
			text={'Cancel'}
			inverse
			dataTest="add-user-cancel"
			on:click={() => modal.close()}
			disabled={loading}
		/>
		<Button
			text={loading ? 'inviting' : 'apply'}
			dataTest="add-user-submit"
			on:click={submit}
			disabled={loading}
		/>
	</div>
</Modal>
