<script lang="ts">
	export let noPadding = false;
	export let extraClass = '';
	export let dark = false;
</script>

<div class="flex {extraClass} w-full" class:px-7={!noPadding}>
	<div class="flex divider w-full" class:dark />
</div>

<style>
	.divider {
		border-bottom: 1px solid #c4c4c4;
	}
	.dark {
		border-bottom: 1px solid #707070;
	}
</style>
