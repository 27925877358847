<script>
	export let open = false;
	import { slide } from 'svelte/transition';
	import Icon from './Icon.svelte';
	const handleClick = () => (open = !open);
</script>

<div class="accordion m-1">
	<div class="header flex">
		<div class="text flex mr-2">
			<slot name="head" />
		</div>

		<button class="ml-auto" on:click={handleClick}>
			<Icon icon="down-arrow" />
		</button>
	</div>

	{#if open}
		<div class="content" transition:slide>
			<slot name="content" />
		</div>
	{/if}
</div>
