<script lang="ts">
	import { getAuthContext } from '$components/providers/contexts';

	import { getRouterContext } from 'src/router/contexts';
	import { goToRoute } from 'src/router/Router.svelte';
	const { routes } = getRouterContext();
	const { authClient, isAuthenticated } = getAuthContext();

	$authClient.token
		.parseFromUrl()
		.then(function (res) {
			$authClient.tokenManager.setTokens(res.tokens);

			isAuthenticated.set(!!$authClient.getAccessToken());
			const targetRoute = sessionStorage.getItem('targetRoute') ?? '';
			if (targetRoute) {
				const route = $routes.find((f) => f.path === targetRoute);
				sessionStorage.removeItem('targetRoute');
				goToRoute(route?.path ?? '/');
			}
		})
		.catch(function (err) {
			console.error('callback failed', err);
		});
</script>
