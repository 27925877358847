import type {
	RepInformation,
	AssociatedOrg,
	ExistingRegistrationRequest,
} from 'ets-clients/dist/customers/v1';
import { getContext } from 'svelte';
import { get, writable } from 'svelte/store';
import { reloadableStore } from 'ets-shared-components/src/lib/reloadable-store';
import EnterpriseCustomers from './enterprise-customers';

type marketplaces = {
	adesa: boolean;
	tradeRev: boolean;
	vlcs: boolean;
	sell: boolean;
};

export enum panelNames {
	'marketplace-access',
	'documents',
	'payment-info',
}

export const marketplaceDisplayNames = {
	adesa: 'ADESA.ca',
	tradeRev: `TradeRev.com`,
	vlcs: `Vehicle logistics centres`,
};

export function buildSetupStores() {
	const repInfo = writable<RepInformation>();
	const selectedDealer = writable<AssociatedOrg>();
	const auctionAccessId = writable<string>();
	const selectedMarketplaces = writable<marketplaces>({
		adesa: true,
		tradeRev: true,
		vlcs: true,
		sell: false,
	});
	const contactPhoneNumber = writable<string>();
	const reloadablePaymentMethods = reloadableStore(() =>
		get(selectedDealer)?.auctionAccessId
			? EnterpriseCustomers.getPaymentMethods(get(selectedDealer)?.auctionAccessId ?? '')
			: Promise.resolve([])
	);

	const currentReg = writable<ExistingRegistrationRequest>();
	const missingPrimaryAddress = writable<boolean>();
	const docusignUrl = writable<string>();

	const reloadableReps = reloadableStore(() =>
		get(currentReg)
			? EnterpriseCustomers.getReps(get(currentReg)?.organizationId ?? '')
			: Promise.resolve([])
	);
	const reloadableRepInfo = reloadableStore(() => EnterpriseCustomers.getRepInfo());

	return {
		selectedDealer,
		selectedMarketplaces,
		contactPhoneNumber,
		auctionAccessId,
		reloadablePaymentMethods,
		reloadableReps,
		reloadableRepInfo,
		repInfo,
		currentReg,
		missingPrimaryAddress,
		docusignUrl,
	};
}

export const SetupContext = {};
export function getSetupContext(): ReturnType<typeof buildSetupStores> {
	return getContext(SetupContext);
}

export function getDisplayMarketplaces(selectedMarketplaces: marketplaces) {
	const displayMarketplaces: string[] = [];

	if (selectedMarketplaces.adesa) {
		displayMarketplaces.push(marketplaceDisplayNames.adesa);
	}
	if (selectedMarketplaces.tradeRev) {
		displayMarketplaces.push(marketplaceDisplayNames.tradeRev);
	}
	if (selectedMarketplaces.vlcs) {
		displayMarketplaces.push(marketplaceDisplayNames.vlcs);
	}
	return displayMarketplaces;
}
