<script context="module" lang="ts">
	import { isMobile } from '$lib/global-stores';
	const MOBILE_BREAKPOINT_PX = 640;
</script>

<script lang="ts">
	let clientWidth: number;
	$: {
		isMobile.set(clientWidth <= MOBILE_BREAKPOINT_PX);
	}
</script>

<div id="responsivecontainer" style="width:100%" class="flex justify-center" bind:clientWidth>
	<slot />
</div>
