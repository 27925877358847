import { isMobile } from './global-stores';
import { get } from 'svelte/store';

export function track(eventName: string, properties?: Record<string, any>) {
	analytics.track(eventName, {
		isMobile: get(isMobile),
		userAgent: navigator.userAgent,
		hostname: location.hostname,
		...properties,
	});
}

export function formatError(error: any) {
	if (typeof error === 'string') return error;
	if (error?.message) return error.message;
	try {
		return `Unknown error: ${JSON.stringify(error)}`;
	} catch {
		return 'Unknown error';
	}
}

window.addEventListener('unhandledrejection', async (ev) => {
	track('Uncaught error', {
		reason: formatError(await ev.promise.catch((err) => err)),
	});
});
window.addEventListener('error', (ev) => {
	track('Uncaught error', {
		reason: formatError(ev.error),
	});
});
