<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	import Icon from './Icon.svelte';

	export let showError = false;
	export let errorText = '';
	export let labelText: string;
	export let fieldName = '';
	export let placeholderText = '';
	export let value: string | number = '';
	export let type = 'text';
	export let maxLength = 150;

	const dispatch = createEventDispatcher();

	let invalidNumerics = ['-', '.', 'e'];

	function onKeyDown(e: KeyboardEvent) {
		if (type === 'number') {
			if (invalidNumerics.find((i) => i == e.key)) {
				e.preventDefault();
			}
		}
	}

	function handleInput(e: Event) {
		value = (e.target as HTMLInputElement).value;
	}
</script>

<div class="flex w-full flex-col">
	{#if labelText}
		<label class="flex mb-1" for={fieldName}>{labelText}</label>
	{/if}

	<input
		id={fieldName}
		{type}
		min="1"
		{value}
		maxlength={maxLength}
		placeholder={placeholderText}
		class:registration-input={!showError}
		class:registration-input-error={showError}
		on:blur={() => dispatch('blur', { fieldName: fieldName })}
		on:input={handleInput}
		on:keydown={onKeyDown}
		data-test={fieldName}
	/>

	<slot name="underInput" />

	{#if showError}
		<div class="flex mt-1">
			<Icon icon="error" extraClass="my-auto" />
			<div class="text-error ml-2" data-test={`error-${fieldName}`}>
				{errorText}
			</div>
		</div>
	{/if}
</div>

<style lang="postcss">
	.registration-input {
		@apply flex w-full pl-2 h-10 border-grey-2 border rounded;
	}
	.registration-input-error {
		@apply flex w-full pl-2 h-10 bg-error-tint border rounded border-error;
	}

	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}
</style>
