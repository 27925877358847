<script lang="ts">
	import TitledElement from '$components/shared/TitledElement.svelte';
	import Button from '$components/shared/Button.svelte';
	import { createEventDispatcher } from 'svelte';
	import EnterpriseCustomers from '$lib/enterprise-customers';
	import type { UserInfo } from './Registration.svelte';
	import Input from '$components/shared/Input.svelte';
	import { ActivityKeys, FormError, hasBlockingIssues } from '$lib/utils';
	import { _, locale } from 'svelte-i18n';
	import NotificationBox from '$components/shared/NotificationBox.svelte';
	import { hasAuctionAccess, repInfo } from '$lib/global-stores';
	import Divider from '$components/shared/Divider.svelte';
	import { goTo } from 'src/router/Router.svelte';
	import { track } from '$lib/analytics';
	import { VerificationStatus } from 'ets-clients/dist/customers/v1';
	import Clickable from 'ets-shared-components/src/components/Clickable.svelte';
	import { getCurrentLocale, setCurrentLocale } from 'src/i18n';
	import Icon from '$components/shared/Icon.svelte';
	import { featureFlags } from '$lib/feature-flags';

	let errors: FormError[] = [];
	let hasBlockingError: boolean;
	let verificationError = false;
	let alreadyExists = false;

	export let userInfo: UserInfo;

	const fields = {
		firstName: 'firstName',
		lastName: 'lastName',
		aaNumber: 'aaNumber',
		aaIdLast4: 'aaIdLast4',
		mobile: 'mobile',
		dealershipName: 'dealershipName',
	};

	$: loading = false;

	const dispatch = createEventDispatcher();

	function nextStep() {
		validate();
		if (errors.length === 0) {
			verifyAaInfo();
		}
	}

	function previousStep() {
		dispatch('previous');
	}

	function validate(field?: string) {
		if (!field) {
			validateAll();
		} else {
			validateField(field);
		}
	}

	function validateAll() {
		errors = [];
		if ($hasAuctionAccess) {
			validateAaNumber();
			validateAaIdLast4();
		}
	}

	function validateAaNumber() {
		if (userInfo.aaNumber?.length != 9) {
			errors.push({
				field: fields.aaNumber,
				error: $_('user-info.aa-number-error'),
			});
		}
	}

	function validateAaIdLast4() {
		if (userInfo.aaIdLast4?.length != 4) {
			errors.push({ field: fields.aaIdLast4, error: $_('user-info.aa-id-number-error') });
		}
	}

	function validateField(field: string) {
		errors = errors.filter((e) => e.field !== field);

		if (field === fields.aaNumber) {
			validateAaNumber();
		}
		if (field === fields.aaIdLast4) {
			validateAaIdLast4();
		}
	}

	function verifyAaInfo() {
		if ($hasAuctionAccess) {
			loading = true;
			hasBlockingError = false;
			alreadyExists = false;
			verificationError = false;
			EnterpriseCustomers.verifyProfile(
				userInfo.aaIdLast4?.toLocaleUpperCase() ?? '',
				userInfo.aaNumber ?? ''
			)
				.then((res) => {
					track('Completed verify event', {
						status: res.verificationStatus,
					});

					if (res.verificationStatus == VerificationStatus.NotVerified) {
						verificationError = true;
					} else if (res.verificationStatus == VerificationStatus.ExistingInOmp) {
						alreadyExists = true;
					} else if (res.verificationStatus == VerificationStatus.Verified) {
						if (res.repInformation?.organizations?.every((e) => hasBlockingIssues(e.issue ?? []))) {
							hasBlockingError = true;
						} else {
							repInfo.set(res.repInformation ?? {});
							dispatch('next');
						}
					}
				})
				.finally(() => {
					loading = false;
				});
		} else {
			dispatch('next');
		}
	}

	function toggleLang() {
		const lang = $locale == 'en' ? 'fr' : 'en';
		track('Language Toggle Clicked', {
			language_selected_type: lang,
		});
		setCurrentLocale(lang);
	}
</script>

{#if $featureFlags.LocalizationToggle}
	<div class="flex ml-auto mb-4 text-primary">
		<Clickable display="flex" on:click={toggleLang}>
			<Icon icon="internationalization" />
			<p class="ml-2">{getCurrentLocale($locale) == 'en' ? 'francais' : 'english'}</p>
		</Clickable>
	</div>
{/if}

<NotificationBox
	type="infoNotification"
	overrideIconType="login"
	headerText={$_('user-info.already-registered')}
	test="ineligible-dealers"
>
	<div slot="content">
		{$_('user-info.add-access-and-services')}
		<a href={OMP_REDIRECT_URI} rel="noopener noreferrer" class="text-primary cursor-pointer"
			>{$_('user-info.here')}</a
		>
	</div>
</NotificationBox>

<TitledElement>
	<div slot="leftHeader" class="flex flex-row">
		<div class="flex font-bold pl-6 text-[18px] my-auto">
			{$_('user-info.sign-up-for-openlane')}
		</div>
		&nbsp
		<div class="flex my-auto">{$_('user-info.3-min-to-complete')}</div>
	</div>
	<div class="flex px-6 pb-6 flex-col mb-2" slot="content">
		{#if verificationError}
			<div class="mt-4">
				<NotificationBox
					type="errorNotification"
					text={$_('user-info.auctionaccess-could-not-confirm-your-identity')}
					test="unable-to-verify-user"
				>
					<div
						slot="belowBodyContent"
						class="flex text-primary cursor-pointer"
						on:click={() => {
							goTo(
								'https://help.openlane.ca/s/contact-us',
								ActivityKeys.ContactUs,
								true,
								getCurrentLocale($locale)
							);
						}}
					>
						{$_('shared.having-trouble')}
					</div>
				</NotificationBox>
			</div>
		{/if}
		{#if alreadyExists}
			<div class="mt-4">
				<NotificationBox
					type="errorNotification"
					text={$_('user-info.you-already-exist')}
					test="unable-to-verify-user"
				/>
			</div>
		{/if}
		<p class="flex my-4">
			{$_('user-info.to-get-you-signed-up')}
		</p>

		{#if !verificationError}
			<div class="flex flex-col mb-6">
				<p class="flex">
					{$_('user-info.youll-also-need')}:
				</p>
				<ul class="list-disc list-inside">
					<li>
						{$_('user-info.an-email-address')}
					</li>
					<li>
						{$_('user-info.bank-account-info')}
					</li>
				</ul>
			</div>
		{/if}

		<div>
			<Divider noPadding />
		</div>

		<p class="font-bold mt-6">
			{$_('user-info.lets-get-started')}!
		</p>

		{#if hasBlockingError}
			<NotificationBox
				type="errorNotification"
				text={$_(
					'user-info.youll-need-to-fix-this-issue-with-auctionaccess-before-you-can-continue-here-please-contact-auctionaccess-support-directly-for-help-fixing-this-issue-and-then-you-can-return-here-to-continue'
				)}
				headerText={$_('user-info.theres-an-issue-with-your-auctionaccess-account')}
				test="has-blocking-error"
			>
				<div
					slot="belowBodyContent"
					class="flex text-primary cursor-pointer"
					on:click={() => {
						goTo(
							'https://help.openlane.ca/s/contact-us',
							ActivityKeys.ContactUs,
							true,
							getCurrentLocale($locale)
						);
					}}
				>
					{$_('shared.having-trouble')}
				</div>
			</NotificationBox>
		{/if}
		{#if $hasAuctionAccess}
			<div class="flex flex-col mb-8 mt-6">
				<div class="flex">
					<Input
						showError={!!errors.find((f) => f.field === fields.aaNumber)}
						errorText={errors.find((f) => f.field === fields.aaNumber)?.error}
						labelText={$_('user-info.aa-number-label')}
						fieldName={fields.aaNumber}
						placeholderText={$_('user-info.aa-number-placeholder')}
						type="number"
						bind:value={userInfo.aaNumber}
						on:blur={(e) => validate(e.detail.fieldName)}
					>
						<div class="flex" slot="underInput">
							<div
								class="flex text-primary cursor-pointer"
								on:click={() => {
									goTo('https://www.auctionaccess.com/index.html', ActivityKeys.NoAuctionAccess);
								}}
							>
								{$_('user-info.under-aa-number-content')}
							</div>
						</div>
					</Input>
				</div>
			</div>

			<div class="flex mb-8">
				<Input
					showError={!!errors.find((f) => f.field === fields.aaIdLast4)}
					errorText={errors.find((f) => f.field === fields.aaIdLast4)?.error}
					labelText={$_('user-info.aa-id-number-label')}
					fieldName={fields.aaIdLast4}
					placeholderText={$_('user-info.aa-id-number-placeholder')}
					bind:value={userInfo.aaIdLast4}
					on:blur={(e) => validate(e.detail.fieldName)}
				>
					<div class="flex text-muted" slot="underInput">
						{$_('user-info.under-aa-id-number-content')}
					</div>
				</Input>
			</div>
		{/if}

		<div class="flex">
			<div class="flex mr-auto">
				<Button
					inverse
					text={$_('shared.back-button-text')}
					dataTest="back-button"
					on:click={() => previousStep()}
				/>
			</div>
			<div class="flex ml-auto">
				<Button
					text={$_('shared.continue-button-text')}
					dataTest="continue-button"
					icon={loading ? 'loading-spinner' : undefined}
					on:click={() => nextStep()}
				/>
			</div>
		</div>
	</div>
</TitledElement>
