<script>
	import Router, { Routes } from './router/Router.svelte';
	import Route from './router/Route.svelte';
	import Registration from '$components/registration/Registration.svelte';
	import GlobalStyles from '$components/providers/GlobalStyles.svelte';
	import AuthProvider from '$components/providers/AuthProvider.svelte';
	import Setup from '$components/setup/Setup.svelte';
	import Callback from '$components/shared/Callback.svelte';
	import ProfileProvider from '$components/providers/ProfileProvider.svelte';
	import AnalyticsProvider from '$components/providers/AnalyticsProvider.svelte';
	import ResponsiveProvider from '$components/providers/ResponsiveProvider.svelte';
	import Completed from '$components/setup/Explore.svelte';
	import FeatureFlagProvider from '$components/providers/FeatureFlagProvider.svelte';
</script>

<div class="registration-root justify-center flex mt-24">
	<GlobalStyles />
	<AnalyticsProvider />
	<Router>
		<AuthProvider>
			<FeatureFlagProvider>
				<ProfileProvider>
					<ResponsiveProvider>
						<div class="flex flex-col">
							<Route path={Routes.setup()} name="setup">
								<Setup />
							</Route>
							<Route path={Routes.callback()} name="callback" anonymous>
								<Callback />
							</Route>
							<Route path={Routes.explore()} name="explore">
								<Completed />
							</Route>
							<Route path={Routes.registration()} name="registration" anonymous>
								<Registration />
							</Route>
						</div>
					</ResponsiveProvider>
				</ProfileProvider>
			</FeatureFlagProvider>
		</AuthProvider>
	</Router>
</div>

<style lang="postcss">
	.registration-root {
		--brand-color: var(--set-brand-color, #00838f);
		--primary-color: var(--set-primary-color, #0061ff);
		--primary-tint-color: var(--set-primary-tint-color, #f5fafc);
		--secondary-color: var(--set-secondary-color, #00537f);
		--black-color: var(--set-black-color, #272d33);
		--grey-1-color: var(--set-grey-1-color, #4d5155);
		--grey-2-color: var(--set-grey-2-color, #c3c3c3);
		--grey-3-color: var(--set-grey-3-color, #d9dce1);
		--grey-4-color: var(--set-grey-4-color, #f3f3f3);
		--success-color: var(--set-success-color, #009e3f);
		--success-tint-color: var(--set-success-tint-color, #e9f5ed);
		--warn-color: var(--set-warn-color, #ffc827);
		--pending-color: var(--set-pending-color, #ffa800);
		--warn-tint-color: var(--set-warn-tint-color, #fef8e6);
		--error-color: var(--set-error-color, #d0130f);
		--error-tint-color: var(--set-error-tint-color, #fdf5f2);
		--info-color: var(--set-info-color, #0061ff);
		--info-tint-color: var(--set-info-tint-color, #f4f8fe);
		--header-color: var(--set-header-color, var(--brand-color));
		--font-size-xs: var(--set-font-size-xs, 10px);
		--font-size-sm: var(--set-font-size-sm, 12px);
		--font-size-base: var(--set-font-size-base, 14px);
		--font-size-lg: var(--set-font-size-lg, 16px);
		--font-size-xl: var(--set-font-size-xl, 20px);
		--font-size-2xl: var(--set-font-size-2xl, 24px);
		--primary-button-color: var(--set-primary-color, #0061ff);
		font-size: var(--font-size-base);
		color: var(--black-color);
		width: 100%;
	}
</style>
