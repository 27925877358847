<script lang="ts">
	import { getAuthContext } from '$components/providers/contexts';

	import { getRouterContext } from './contexts';

	export let path: string | undefined = undefined;
	export let name: string;
	export let anonymous = false;

	const { activeRoute, registerRoute, isAnonymous, params } = getRouterContext();
	const { isAuthenticated } = getAuthContext();

	let id = registerRoute(path);

	let isActive: boolean;

	$: {
		isActive = $activeRoute === id;
		if (isActive) {
			isAnonymous.set(anonymous);
		}
	}

	$: {
		if (isActive) {
			analytics.page(name);
		}
	}
</script>

<!-- svelte-ignore missing-declaration -->
{#if isActive && ($isAuthenticated || $isAnonymous || TEST)}
	<slot params={$params} />
{/if}
