import { writable } from 'svelte/store';

export enum AvailableFeatureFlags {
	LocalizationToggle = 'omp_feature_localization_toggle',
}

export const reverseFlags = new Map<string, string>();
Object.values(AvailableFeatureFlags).forEach((flag, i) => {
	const flagValue: string = Object.keys(AvailableFeatureFlags)[i];
	reverseFlags.set(flag, flagValue);
});

export const availableTreatments = [AvailableFeatureFlags.LocalizationToggle];

type Flags = {
	[j in keyof typeof AvailableFeatureFlags]: boolean;
};

export class FeatureFlags implements Flags {
	LocalizationToggle = false;
}

export const featureFlags = writable(new FeatureFlags());
