<script lang="ts">
	import Button from '$components/shared/Button.svelte';
	import TitledElement from '$components/shared/TitledElement.svelte';
	import { ActivityKeys } from '$lib/utils';
	import { goToRoute } from 'src/router/Router.svelte';
	import { createEventDispatcher } from 'svelte';
	import { _ } from 'svelte-i18n';

	const dispatch = createEventDispatcher();
</script>

<TitledElement useOpenlaneHeader testData="already-exists">
	<div slot="content" class="flex flex-col p-6">
		<div class="flex my-4">
			<p class="flex text-[18px] font-bold">{$_('email-already-exists.email-already-exists')}</p>
		</div>

		<div class="flex mt-1 mb-6">
			<p class="flex text-lg">
				{$_('email-already-exists.login')}
			</p>
		</div>

		<div class="flex">
			<div class="flex mr-auto">
				<Button
					inverse
					text={$_('shared.back-button-text')}
					dataTest="back-button"
					on:click={() => dispatch('back')}
				/>
			</div>
			<div class="flex ml-auto">
				<Button
					text={$_('email-already-exists.continue-to-setup')}
					dataTest="continue-button"
					on:click={() => goToRoute('/setup', ActivityKeys.ContinueToSetup)}
				/>
			</div>
		</div>
	</div>
</TitledElement>
