<script lang="ts">
	import {
		availableTreatments,
		AvailableFeatureFlags,
		reverseFlags,
		featureFlags,
		FeatureFlags,
	} from '$lib/feature-flags';
	import { SplitFactory } from '@splitsoftware/splitio';
	import { track } from '$lib/analytics';
	import EnterpriseCustomers from '$lib/enterprise-customers';

	let ready = false;
	// Instantiate the SDK. CDN will expose splitio globally
	const factory: SplitIO.ISDK = SplitFactory({
		core: {
			authorizationKey: SPLIT_KEY,
			key: EnterpriseCustomers.accessToken ? EnterpriseCustomers.ccMasterId : '1G66s22F1', //this is just a fake user because we need split to give us user level flags
		},
		startup: {
			readyTimeout: 1.5,
		},
	});

	const client: SplitIO.IClient = factory.client();

	client.once(client.Event.SDK_READY, function () {
		track('Split treaments initialize');
		updateAllTreatments();
	});

	client.on(client.Event.SDK_UPDATE, function () {
		track('Split treatments updated');
		updateAllTreatments();
	});

	function updateAllTreatments() {
		let treatments = client.getTreatments(availableTreatments);
		let returnedTreatments = new FeatureFlags();

		for (const k in treatments) {
			if (Object.values(AvailableFeatureFlags).includes(<any>k)) {
				if (treatments[k] != 'control') {
					let flagValue = reverseFlags.get(k);
					(returnedTreatments as any)[flagValue!] = treatments[k] == 'on';
				}
			}
		}
		$featureFlags = returnedTreatments;
		ready = true;
	}
</script>

{#if !ready}
	<p>Loading...</p>
{:else}
	<slot />
{/if}
