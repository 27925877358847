<svelte:head>
	<link
		href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;700&display=swap"
		rel="stylesheet"
	/>
	<link
		href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap"
		rel="stylesheet"
	/>
</svelte:head>

<style global lang="postcss">
	@tailwind base;
	@tailwind components;
	@tailwind utilities;

	* {
		font-family: var(--font-family, 'Heebo', sans-serif);
		letter-spacing: 0.25px;
		-webkit-font-smoothing: subpixel-antialiased;
	}
</style>
