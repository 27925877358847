<script lang="ts">
	import { createEventDispatcher } from 'svelte';

	export let disabled = false;
	export let display = 'inline-block';
	export let dataTest: string | undefined = undefined;
	const dispatch = createEventDispatcher<{ click: void }>();
</script>

<div
	class="cursor-pointer {display}"
	class:disabled
	on:click={() => !disabled && dispatch('click')}
	data-test={dataTest}
>
	<slot />
</div>

<style lang="postcss">
	.disabled {
		@apply cursor-not-allowed;
	}
</style>
