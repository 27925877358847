<script lang="ts">
	import EnterpriseCustomers from '$lib/enterprise-customers';
	import { _ } from 'svelte-i18n';
	import { Logger } from '$lib/utils';
	import Button from './Button.svelte';
	import ModalHeader from './ModalHeader.svelte';
	import Modal from './Modal.svelte';
	import Input from './Input.svelte';
	import { getSetupContext } from '$lib/setup-utils';
	import { formStore } from '$lib/global-stores';

	const { currentReg, selectedDealer, reloadablePaymentMethods } = getSetupContext();

	const auctionAccessId = $selectedDealer.auctionAccessId ?? '';
	let loading = false;
	let errorMessage = '';
	let modal: Modal;

	export async function open() {
		modal.open();
	}

	const form = formStore({
		initialValue: {
			institutionNumber: '',
			branchNumber: '',
			accountNumber: '',
		},
		validate(val) {
			const errors: Record<string, string> = {};
			if (!val.institutionNumber.match(/^\d{3}$/))
				errors.institutionNumber = 'Please enter a valid institution number';
			if (!val.branchNumber.match(/^\d{5}$/))
				errors.branchNumber = 'Please enter a valid branch number';
			if (!val.accountNumber.match(/^\d+$/))
				errors.accountNumber = 'Please enter a valid account number';
			return errors;
		},
		async submit({ accountNumber, branchNumber, institutionNumber }) {
			if (!$currentReg.organizationId) {
				Logger.error('Failed to create payment method, missing org ID');
				return;
			}
			await EnterpriseCustomers.createPaymentMethod(
				{
					$type: 'PaymentMethodRequest',
					name: 'ACH',
					auctionAccessId,
					institutionNumber,
					branchNumber,
					accountNumber,
					status: 'Valid',
					sourceId: '1234', // TODO: ECS needs to allow null here, as per swagger
					isActive: true,
					region: 'CA',
				},
				$currentReg.organizationId
			)
				.then(() => {
					reloadablePaymentMethods.reload();
					modal.close();
				})
				.catch((err) => {
					errorMessage = 'Something went wrong';
					Logger.error('Failed to create payment method:', err);
					throw err;
				});
		},
	});
</script>

<Modal bind:this={modal} size="small">
	<ModalHeader title="Add account details" />
	<p class="text-left mb-6">Your bank account information will be kept encrypted and secure.</p>
	<form on:submit|preventDefault={() => form.submit()}>
		<div class="flex gap-x-8">
			<fieldset class="flex-1">
				<Input
					showError={!!$form.errors.institutionNumber}
					errorText={$form.errors.institutionNumber}
					labelText={$_('manual-bank-account.institution-number')}
					placeholderText={'###'}
					bind:value={$form.institutionNumber}
				/>
			</fieldset>
			<fieldset class="flex-1">
				<Input
					showError={!!$form.errors.branchNumber}
					errorText={$form.errors.branchNumber}
					labelText={$_('manual-bank-account.branch-number')}
					placeholderText={'#####'}
					bind:value={$form.branchNumber}
				/>
			</fieldset>
		</div>
		<fieldset>
			<Input
				showError={!!$form.errors.accountNumber}
				errorText={$form.errors.accountNumber}
				labelText={$_('manual-bank-account.account-number')}
				placeholderText={'######'}
				bind:value={$form.accountNumber}
			/>
		</fieldset>
		<footer class="mt-6">
			<Button fullWidth inverse text="Cancel" on:click={() => close()} />
			<Button fullWidth text={loading ? 'Adding' : 'Add'} type="submit" disabled={loading} />
		</footer>
	</form>
</Modal>

<style lang="postcss">
	input {
		@apply w-full border;
	}
	form {
		@apply contents;
	}
	footer {
		@apply flex w-full gap-x-8;
	}
</style>
