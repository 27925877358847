import { Role } from 'ets-clients/dist/customers/v1';
import { writableList } from 'ets-shared-components/src/lib/utils';

export type permissions =
	| 'Navigate site'
	| 'Browse & search vehicles'
	| 'Watch list'
	| 'Share listings'
	| 'Bid on vehicles'
	| 'Negotiate'
	| 'Add and launch vehicles'
	| 'Sell vehicles'
	| 'Checkout vehicles'
	| 'Invoices'
	| 'Payment methods'
	| 'Manage dealership and staff';

export const permissionList: permissions[] = [
	'Navigate site',
	'Browse & search vehicles',
	'Watch list',
	'Share listings',
	'Bid on vehicles',
	'Negotiate',
	'Add and launch vehicles',
	'Sell vehicles',
	'Checkout vehicles',
	'Invoices',
	'Payment methods',
	'Manage dealership and staff',
];

class RoleType {
	public role: Role;
	public title: string;
	public permissions: permissions[];
	public exclusiveWithRoles: Role[];
	constructor(roleType: RoleType) {
		(this.role = roleType.role),
			(this.title = roleType.title),
			(this.permissions = roleType.permissions),
			(this.exclusiveWithRoles = roleType.exclusiveWithRoles);
	}
}

const accountant = new RoleType({
	role: Role.Accountant,
	title: 'Accountant',
	permissions: ['Checkout vehicles', 'Invoices', 'Payment methods'],
	exclusiveWithRoles: [Role.Admin],
});

const admin = new RoleType({
	role: Role.Admin,
	title: 'Admin',
	permissions: [...permissionList],
	exclusiveWithRoles: [
		Role.Accountant,
		Role.Bidder,
		Role.Buyer,
		Role.Owner,
		Role.Seller,
		Role.ViewOnly,
	],
});

const buyer = new RoleType({
	role: Role.Buyer,
	title: 'Buyer',
	permissions: [
		'Navigate site',
		'Browse & search vehicles',
		'Share listings',
		'Bid on vehicles',
		'Negotiate',
		'Watch list',
		'Checkout vehicles',
	],
	exclusiveWithRoles: [Role.Admin, Role.Bidder],
});

const bidder = new RoleType({
	role: Role.Bidder,
	title: 'Bidder',
	permissions: [
		'Navigate site',
		'Browse & search vehicles',
		'Share listings',
		'Bid on vehicles',
		'Negotiate',
		'Watch list',
	],
	exclusiveWithRoles: [Role.Admin, Role.Buyer],
});

const seller = new RoleType({
	role: Role.Seller,
	title: 'Seller',
	permissions: ['Navigate site', 'Negotiate', 'Add and launch vehicles', 'Sell vehicles'],
	exclusiveWithRoles: [Role.Admin],
});

const view = new RoleType({
	role: Role.ViewOnly,
	title: 'View',
	permissions: ['Watch list', 'Share listings', 'Navigate site', 'Watch list'],
	exclusiveWithRoles: [Role.Admin],
});

const inventoryCreator = new RoleType({
	role: Role.InventoryCreator,
	title: 'Inventory Creator',
	permissions: ['Add and launch vehicles'],
	exclusiveWithRoles: [Role.Admin],
});

export const roles = [admin, buyer, bidder, seller, inventoryCreator, accountant, view];
export const selectedRoles = writableList<Role>();
