<script lang="ts" context="module">
	import type { Readable } from 'svelte/store';
	import { getContext } from 'svelte';
	const key = {};
	export function getModalProviderContext(): Readable<HTMLElement> {
		return getContext(key);
	}
</script>

<script lang="ts">
	import { setContext } from 'svelte';
	import { writable } from 'svelte/store';

	const modals = writable<HTMLElement>();
	setContext(key, modals);
</script>

<div id="modals" bind:this={$modals} />
<slot />
