<script lang="ts">
	import Icon, { IconType } from './Icon.svelte';
	import { isMobile } from '$lib/global-stores';

	type notificationType = 'infoNotification' | 'warningNotification' | 'errorNotification';
	export let type: notificationType;
	export let overrideIconType: IconType | undefined = undefined;
	export let text: string | undefined = undefined;
	export let headerText: string | undefined = undefined;
	export let test: string;
</script>

<div
	class="infobox"
	class:errorNotification={type === 'errorNotification'}
	data-test={test}
	class:warningNotification={type === 'warningNotification'}
	class:mobile={$isMobile}
>
	<div class="flex m-3">
		<Icon icon={overrideIconType ? overrideIconType : type} />
	</div>
	<div class="flex flex-col m-3">
		{#if headerText}
			<div class="flex my-auto text-left font-bold text-lg">{headerText}</div>
		{/if}
		<div class="flex my-auto mt-1 text-left">
			{#if text}
				{text}
			{:else}
				<slot name="content" />
			{/if}
		</div>
		<slot name="belowBodyContent" />
	</div>
</div>

<style lang="postcss">
	.infobox {
		@apply bg-info-tint border border-info mb-6 rounded-md flex w-full;

		&.warningNotification {
			@apply bg-warn-tint border-warn;
		}
		&.errorNotification {
			@apply bg-white border-error border-2 border-l-4;
			&.mobile {
				@apply w-[330px] min-w-[330px];
			}
		}
		&.mobile {
			@apply w-[375px] min-w-[375px];
		}
	}
</style>
