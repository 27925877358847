<script lang="ts">
	import { roles as roleList, permissionList, selectedRoles } from '$lib/roles-and-permissions';
	import Divider from '$components/shared/Divider.svelte';
	import Icon from '$components/shared/Icon.svelte';
	import { _ } from 'svelte-i18n';
	import { isMobile } from '$lib/global-stores';
	import Accordion from './Accordion.svelte';
</script>

<div class="flex flex-col my-6">
	<div class="font-[24px] mr-auto font-bold">
		{$_('role-selector.assign-roles')}
	</div>
	<div class="flex">
		{$_('role-selector.select-roles-for-user')}
	</div>
</div>

{#if !$isMobile}
	<div class="flex flex-row">
		<div class="flex w-1/4 flex-col text-muted">
			<div class="flex">Roles and</div>
			<div class="flex">permissions</div>
		</div>

		<div class="grid grid-cols-7 justify-evenly flex w-3/4">
			{#each roleList as role}
				{@const excluded = $selectedRoles.some((t) => role.exclusiveWithRoles.includes(t))}
				<div class="flex flex-col">
					<div class="flex mx-auto" class:text-muted={excluded}>
						{role.title}
					</div>
					<div class="flex m-auto">
						<input
							type="checkbox"
							data-test={role.role}
							disabled={excluded}
							checked={$selectedRoles.includes(role.role)}
							on:change={() => selectedRoles.toggle(role.role)}
						/>
					</div>
				</div>
			{/each}
		</div>
	</div>

	<Divider noPadding dark />

	<div class="flex flex-col">
		{#each permissionList as permission}
			<div class="flex flex-row my-2 w-full">
				<div class="flex w-1/4">{permission}</div>
				<div class="grid grid-cols-7 flex w-3/4">
					{#each roleList as role}
						{#if role.permissions.includes(permission)}
							<div class="flex">
								<div class="flex mx-auto my-auto">
									<Icon
										icon={$selectedRoles.includes(role.role) ? 'blue-checkmark' : 'gray-checkmark'}
									/>
								</div>
							</div>
						{:else}
							<div class="flex grid" />
						{/if}
					{/each}
				</div>
			</div>
			<Divider noPadding />
		{/each}
	</div>
{:else}
	{#each roleList as role}
		{@const excluded = $selectedRoles.some((t) => role.exclusiveWithRoles.includes(t))}
		<Divider noPadding extraClass="py-1" />
		<Accordion>
			<div class="flex" slot="head">
				<div class="flex mr-2">
					<input
						type="checkbox"
						data-test={role.role}
						disabled={excluded}
						checked={$selectedRoles.includes(role.role)}
						on:change={() => selectedRoles.toggle(role.role)}
					/>
				</div>
				<div class="flex mr-auto font-bold">
					{role.title}
				</div>
			</div>
			<div class="flex mt-2" slot="content">
				<ul class="list-disc list-inside text-left">
					{#each role.permissions as permission}
						<li>
							{permission}
						</li>
					{/each}
				</ul>
			</div>
		</Accordion>
	{/each}
{/if}
