<script lang="ts">
	import { writable } from 'svelte/store';
	import { setAuthContext } from './contexts';
	import { OktaAuth } from '@okta/okta-auth-js';
	import { getRouterContext } from 'src/router/contexts';
	import Icon from '$components/shared/Icon.svelte';
	import { onMount } from 'svelte';

	const { activeRoute, isAnonymous, routes } = getRouterContext();

	const isAuthenticated = writable<boolean>(false);
	const authClient = writable<OktaAuth>(
		new OktaAuth({ ...OKTA_CONFIG, tokenManager: { autoRemove: true, autoRenew: false } })
	);
	setAuthContext({
		isAuthenticated,
		authClient,
	});

	onMount(() => {
		$authClient.start();
		isAuthenticated.set(!!$authClient.getAccessToken());
		return () => $authClient.stop();
	});

	function storeTargetRoute() {
		sessionStorage.setItem('targetRoute', $routes.find((f) => f.id === $activeRoute)?.path ?? '');
	}

	$: {
		if (!$isAuthenticated && !$isAnonymous && !TEST) {
			$authClient.tokenManager.get('accessToken').then((accessToken) => {
				if (!accessToken) {
					storeTargetRoute();
					$authClient.token.getWithRedirect({
						scopes: ['profile', 'openid', 'email'],
					});
				}
			});
		}
	}
</script>

<!-- svelte-ignore missing-declaration -->
{#if $isAuthenticated || $isAnonymous || TEST}
	<slot />
{:else}
	<Icon icon="loading-spinner" />
{/if}
