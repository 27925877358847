<script lang="ts">
	import PlaidLinker from '$components/shared/PlaidLinker.svelte';
	import { _ } from 'svelte-i18n';
	import { getSetupContext } from '$lib/setup-utils';
	import TitledElement from '$components/shared/TitledElement.svelte';
	import ManualBankAccountModal from '$components/shared/ManualBankAccountModal.svelte';
	import { createEventDispatcher } from 'svelte';

	const { reloadablePaymentMethods } = getSetupContext();

	let dispatch = createEventDispatcher();
	let loading = true;

	$: {
		loading = true;
		$reloadablePaymentMethods
			.then((res) => {
				if (res.length) {
					dispatch('next');
				}
			})
			.finally(() => (loading = false));
	}

	let modal: ManualBankAccountModal;
</script>

{#if loading}
	{$_('shared.loading')}
{:else}
	<TitledElement title={$_('add-payment.add-a-payment-method')}>
		<div class="flex px-6 pb-6 flex-col mb-2 mt-6" slot="content">
			<p class="flex mb-8 text-left text-lg text-muted">
				{$_('payment-info.to-buy-from-our-most-popular-auction')}
			</p>

			<p class="flex mb-6">
				{$_('add-payment.add-a-bank-account')}
			</p>

			<div class="mt-3">
				<PlaidLinker />
			</div>
			<ManualBankAccountModal bind:this={modal} />
			<div
				class="flex text-info cursor-pointer ml-auto mt-2"
				on:click={() => {
					modal.open();
				}}
			>
				{$_('add-payment.add-account-manually')}
			</div>
			<div
				data-test="continue-button"
				class="flex text-info cursor-pointer mr-auto mt-2"
				on:click={() => {
					dispatch('next');
				}}
			>
				{$_('add-payment.remind-me-later')}
			</div>
		</div>
	</TitledElement>
{/if}
