<script lang="ts">
	import Button from '$components/shared/Button.svelte';
	import Dropdown from '$components/shared/Dropdown.svelte';
	import type { DropdownOption } from '$components/shared/Dropdown.svelte';
	import TitledElement from '$components/shared/TitledElement.svelte';
	import EnterpriseCustomers from '$lib/enterprise-customers';
	import { createEventDispatcher } from 'svelte';
	import { getSetupContext } from '$lib/setup-utils';
	import { ActivityKeys, hasBlockingIssues, isOwner } from '$lib/utils';
	import NotificationBox from '$components/shared/NotificationBox.svelte';
	import { _, locale } from 'svelte-i18n';
	import type { AssociatedOrg } from 'ets-clients/dist/customers/v1';
	import { profile } from '$components/providers/ProfileProvider.svelte';
	import { goTo } from 'src/router/Router.svelte';
	import { track } from '$lib/analytics';
	import Icon from '$components/shared/Icon.svelte';
	import { getCurrentLocale } from 'src/i18n';

	let dispatch = createEventDispatcher();

	let orgs: AssociatedOrg[];
	let ineligibleOrgs: AssociatedOrg[];
	let eligibleOrgs: AssociatedOrg[];
	let displayLocation: string;

	const { selectedDealer, auctionAccessId, reloadableRepInfo } = getSetupContext();

	let options: DropdownOption[] = [];
	let selectedId: string;
	let hasIneligibleDealerships: boolean;
	let existsInEcs: boolean;
	let isDealershipOwner: boolean;
	let loading = true;
	let verifying = false;

	$: {
		if ($profile) {
			fetchOrgs();
		}
	}

	function fetchOrgs() {
		$reloadableRepInfo
			.then((res) => {
				orgs = res.organizations ?? [];
				auctionAccessId.set(res.auctionAccessId ?? '');
			})
			.catch((err) => console.error(err))
			.finally(() => {
				if ($selectedDealer) {
					selectedId = $selectedDealer.auctionAccessId ?? '';
				}

				ineligibleOrgs = orgs?.filter((f) => hasBlockingIssues(f.issue ?? [])) ?? [];
				hasIneligibleDealerships = ineligibleOrgs.length > 0;
				eligibleOrgs = orgs?.filter(
					(f) =>
						!ineligibleOrgs.find((g) => g.auctionAccessId === f.auctionAccessId) &&
						!$profile.organizationSummaries?.find((g) => g.auctionAccessId === f.auctionAccessId) &&
						f.auctionAccessId
				);

				if (eligibleOrgs?.length === 1) {
					selectedId = eligibleOrgs[0].auctionAccessId ?? '';
					selectedDealer.set(eligibleOrgs[0]);
				}
				track('orgs from repInfo to populate dealer select.', {
					orgs,
					ineligibleOrgs,
					eligibleOrgs,
				});

				loading = false;
			});
	}

	$: {
		if (eligibleOrgs) {
			options = eligibleOrgs.map((m) => {
				return {
					text: `${m.displayName} (${m.role})` ?? '',
					id: m.auctionAccessId ?? '',
				};
			});
		}
	}

	$: {
		let org = eligibleOrgs?.find((f) => f.auctionAccessId === selectedId);
		if (org) {
			selectedDealer.set(org);
			displayLocation = `${$selectedDealer.city}, ${$selectedDealer.state}, ${$selectedDealer.postalCode}`;
			existsInEcs = ($selectedDealer.existingSources?.length ?? 0) > 0;
			isDealershipOwner = isOwner($selectedDealer.role ?? '');
			selectedId = $selectedDealer.auctionAccessId ?? '';
		}
	}

	async function nextStep() {
		if (!hasBlockingIssues($selectedDealer.issue ?? [])) {
			dispatch('next');
		}
	}
</script>

{#if loading}
	<Icon icon="loading-spinner" />
{:else if eligibleOrgs.length}
	<TitledElement title={$_('dealer-select.youre-almost-there')} testData="dealer-select">
		<div class="flex flex-col px-6 pb-6" slot="content">
			{#if hasIneligibleDealerships}
				<NotificationBox
					type="warningNotification"
					text={$_(
						'dealer-select.the-list-below-contains-dealers-youre-associated-to-that-can-be-used-currently-if-you-need-to-sign-up-with-one-that-isnt-in-the-list'
					)}
					headerText={$_(
						'dealer-select.one-or-more-of-the-dealerships-youre-linked-to-in-auctionaccess-cannot-be-used-right-now'
					)}
					test="ineligible-dealers"
				>
					<div slot="belowBodyContent">
						<ul class="list-disc ml-3 mt-2">
							{#each ineligibleOrgs as org}
								<li>
									{org.displayName}
								</li>
							{/each}
						</ul>
					</div>
				</NotificationBox>
			{/if}
			<div class="flex flex-col my-6">
				<div class="flex flex-col">
					<Dropdown
						labelText={$_('phone-number.dropdown-label-text')}
						dropdownId="dealerDropdown"
						bind:selectedId
						{options}
					/>
				</div>
			</div>

			<div class="flex mb-6">
				{$_('dealer-select.if-you-dont-see')}
			</div>

			{#if $selectedDealer}
				{#if hasBlockingIssues($selectedDealer.issue ?? [])}
					<NotificationBox
						type="errorNotification"
						text={$_(
							'user-info.youll-need-to-fix-this-issue-with-auctionaccess-before-you-can-continue-here-please-contact-auctionaccess-support-directly-for-help-fixing-this-issue-and-then-you-can-return-here-to-continue'
						)}
						headerText={$_('user-info.theres-an-issue-with-your-auctionaccess-account')}
						test="has-blocking-issue"
					/>
				{/if}
			{/if}
			<div class="flex">
				<div class="flex ml-auto">
					<Button
						text={$_('shared.continue-button-text')}
						dataTest="continue-button"
						icon={verifying ? 'loading-spinner' : undefined}
						on:click={() => nextStep()}
					/>
				</div>
			</div>
		</div>
	</TitledElement>
{:else}
	<TitledElement title={$_('dealer-select.youre-registered')} testData="dealer-select">
		<div slot="content" class="flex flex-col p-6">
			<div class="flex mb-8">
				{$_('dealer-select.youre-already-linked')}
			</div>

			<Button
				fullWidth
				icon="external"
				on:click={() =>
					goTo(OMP_REDIRECT_URI, ActivityKeys.TakeMeToOpenlane, true, getCurrentLocale($locale))}
				text={$_('summary.take-me-to-openlane')}
			/>
		</div>
	</TitledElement>
{/if}

<style lang="postcss">
	div {
		.halfWidth {
			@apply w-1/2;
		}
	}
</style>
