<script lang="ts">
	import { createEventDispatcher } from 'svelte';
	import { fly } from 'svelte/transition';
	import Icon from './Icon.svelte';
	import { isMobile } from '$lib/global-stores';

	export let kind: 'success' | 'info' | 'error';
	export let message = '';
	export let dismissable = true;
	export let transition = false;
	const dispatch = createEventDispatcher<{ dismiss: void }>();

	function getIcon(kind: 'success' | 'info' | 'error') {
		if (kind === 'success') {
			return 'banner-success';
		} else if (kind === 'info') {
			return 'banner-info';
		} else {
			return 'banner-error';
		}
	}
</script>

<div
	class="banner"
	class:success={kind === 'success'}
	class:info={kind === 'info'}
	class:error={kind === 'error'}
	class:dismissable
	class:mobile={$isMobile}
	on:click={() => {
		if (!dismissable) return;
		dispatch('dismiss');
	}}
	transition:fly={{ y: 10, duration: transition ? 300 : 0 }}
>
	<div style="flex-shrink: 0">
		<Icon icon={getIcon(kind)} />
	</div>
	<slot>
		{message}
	</slot>
</div>

<style lang="postcss">
	.banner {
		@apply border-[2px] border-l-[9px] rounded flex gap-x-4 p-4 items-start w-[647px] min-w-[647px];
		&.mobile {
			@apply w-[375px] min-w-[375px];
		}
		&.success {
			@apply border-success;
		}
		&.info {
			@apply border-info;
		}
		&.error {
			@apply border-error;
		}
		&.dismissable {
			@apply cursor-pointer;
		}
	}
</style>
