<script context="module" lang="ts">
	import { featureFlags } from '$lib/feature-flags';

	export function goToRoute(route: string, clickedTitle = '') {
		if (clickedTitle) {
			track(`${clickedTitle} clicked`);
		}
		const url = new URL(window.location as any);
		url.pathname = route;
		window.history.pushState(null, '', url.toString());
		window.dispatchEvent(new Event('registration:route-change'));
	}

	export function goTo(
		url: string,
		clickedTitle: string,
		newTab = true,
		lang: string | undefined = undefined
	) {
		const target = new URL(url);
		if (lang && get(featureFlags).LocalizationToggle) {
			target.searchParams.set('lang', lang);
		}
		const targetString = target.toString();

		track(`${clickedTitle} clicked`, {
			url: targetString,
		});

		window.open(targetString, newTab ? '_blank' : '_self', 'noopener');
	}

	export class Routes {
		static setup() {
			return '/setup';
		}
		static registration() {
			return '/';
		}
		static explore() {
			return '/explore';
		}
		static callback() {
			return '/callback';
		}
	}
</script>

<script lang="ts">
	import { get, writable } from 'svelte/store';
	import { setRouterContext } from './contexts';
	import type { Route } from './contexts';
	import { track } from '$lib/analytics';

	const activeRoute = writable<number | null>(null);
	const routes = writable<Route[]>([]);
	const params = writable<Record<string, string>>({});
	const isAnonymous = writable<boolean>(true);
	const currentPath = writable(getCurrentPath());
	let globalId = 0;

	setRouterContext({
		activeRoute,
		params,
		routes,
		isAnonymous,
		registerRoute(path?: string) {
			let existingRoute = $routes.find((f) => f.path === path);
			if (existingRoute) {
				return existingRoute.id;
			}

			const id = globalId++;
			routes.update((r) => [...r, { id, path }]);
			return id;
		},
	});

	getDocumentEvent();

	window.addEventListener('popstate', () => {
		currentPath.set(getCurrentPath());
	});
	window.addEventListener('registration:route-change', () => {
		currentPath.set(getCurrentPath());
	});

	$: {
		const match = routeMatch($routes, $currentPath);
		if (match) {
			activeRoute.set(match.route.id);
			params.set(match.params);
		}
	}

	function routeMatch(routes: Route[], currentPath: string) {
		for (const route of routes) {
			if (!route.path) return { route, params: {} };

			const md = new RegExp(route.path.replace(/:([^/]+)/g, '(?<$1>[^/]+)')).exec(currentPath);
			if (md) {
				return {
					route,
					params: { ...md.groups } ?? {},
				};
			}
		}
	}

	function getCurrentPath() {
		return window.location.pathname ?? '';
	}

	function getDocumentEvent() {
		const docEvent = new URLSearchParams(window.location.search).get('event');

		if (docEvent) {
			track('Docusign event', { event: docEvent });
			window.history.replaceState(null, '', window.location.pathname);
		}
	}
</script>

<slot />
