import { getContext, setContext } from 'svelte';
import type { Readable, Writable } from 'svelte/store';

const ROUTER = {};
interface RouterContext {
	activeRoute: Readable<number | null>;
	params: Writable<Record<string, string>>;
	routes: Writable<Route[]>;
	isAnonymous: Writable<boolean>;
	registerRoute: (path?: string) => number;
}

export interface Route {
	id: number;
	path?: string;
}

export function getRouterContext() {
	return getContext<RouterContext>(ROUTER);
}

export function setRouterContext(context: RouterContext) {
	return setContext(ROUTER, context);
}
