<script lang="ts" context="module">
	export type UserInfo = {
		firstName?: string;
		lastName?: string;
		aaNumber?: string;
		aaIdLast4?: string;
	};
	export type Email = {
		emailAddress?: string;
		retypedEmailAddress?: string;
	};
</script>

<script lang="ts">
	import UserInformation from './UserInformation.svelte';
	import AdesaIdEmail from './AdesaIdEmail.svelte';
	import EmailSent from './EmailSent.svelte';
	import { onMount, setContext } from 'svelte';
	import RegistrationUtils, {
		buildRegistrationStores,
		getRegistrationContext,
		RegistrationContext,
	} from '$lib/registration-utils';
	import AlreadyExists from './AlreadyExists.svelte';
	import EnterpriseCustomers from '$lib/enterprise-customers';
	import { formatError, track } from '$lib/analytics';
	import { hasAuctionAccess, repInfo } from '$lib/global-stores';

	setContext(RegistrationContext, buildRegistrationStores());

	type RegistrationStep = 'userinfo' | 'adesaidemail' | 'emailsent' | 'alreadyexists';

	const { alreadyExists } = getRegistrationContext();

	let userInfo: UserInfo = {};
	let email: Email = {};
	let loading = false;

	let step: RegistrationStep = 'userinfo';

	onMount(() => {
		track('Began Registration flow');
		hasAuctionAccess.set(true);
	});

	$: {
		track('Registration step viewed', {
			step: step,
		});
	}

	function createId() {
		track('Create user ID', { id: email.emailAddress });
		loading = true;
		alreadyExists.set(false);
		EnterpriseCustomers.createId(
			email.emailAddress!,
			$repInfo.firstName!,
			$repInfo.lastName!,
			userInfo.aaNumber!
		)
			.then((res) => {
				if (res.userCreated == false) {
					alreadyExists.set(true);
				}
				track('Unauthed registration ended');
				next();
			})
			.catch((err) => {
				track('Failed to create adesa ID', {
					error: formatError(err),
				});
				next();
			})
			.finally(() => {
				loading = false;
			});
	}

	function next() {
		switch (step) {
			case 'userinfo':
				step = 'adesaidemail';
				break;
			case 'adesaidemail':
				if ($alreadyExists) {
					step = 'alreadyexists';
				} else {
					step = 'emailsent';
				}
				break;
		}
	}

	function back() {
		switch (step) {
			case 'userinfo':
				window.history.back();
				break;
			case 'adesaidemail':
				step = 'userinfo';
				break;
			case 'alreadyexists':
				step = 'adesaidemail';
				break;
			case 'emailsent':
				step = 'adesaidemail';
				break;
		}
	}
</script>

<img src={RegistrationUtils.openlaneLogoImage()} class="w-[291px] mx-auto mb-14" alt="openlane" />
{#if step === 'userinfo'}
	<UserInformation {userInfo} on:next={next} on:previous={back} />
{/if}
{#if step === 'adesaidemail'}
	<AdesaIdEmail {loading} {email} on:createId={createId} on:next={next} on:previous={back} />
{/if}
{#if step === 'emailsent'}
	<EmailSent {email} on:back={back} on:createId={createId} />
{/if}
{#if step === 'alreadyexists'}
	<AlreadyExists on:back={back} />
{/if}
