<script lang="ts">
	import Button from '$components/shared/Button.svelte';
	import Input from '$components/shared/Input.svelte';
	import TitledElement from '$components/shared/TitledElement.svelte';
	import type { FormError } from '$lib/utils';
	import { createEventDispatcher } from 'svelte';
	import { _ } from 'svelte-i18n';
	import type { Email } from './Registration.svelte';
	import { repInfo } from '$lib/global-stores';

	const dispatch = createEventDispatcher();

	let errors: FormError[] = [];
	export let email: Email;

	let fields = {
		emailAddress: 'emailAddress',
		retypedEmailAddress: 'retypedEmailAddress',
	};

	export let loading = false;

	function nextStep() {
		validate();
		if (errors.length === 0) {
			dispatch('createId');
		}
	}

	function previousStep() {
		dispatch('previous');
	}

	function validate() {
		errors = [];
		if (!email.emailAddress) {
			errors.push({ field: fields.emailAddress, error: $_('adesa-id-email.email-required-error') });
		}
		if (email.emailAddress !== undefined && !email.emailAddress?.includes('@')) {
			errors.push({ field: fields.emailAddress, error: $_('adesa-id-email.email-format-error') });
		}
		if (email.emailAddress != email.retypedEmailAddress) {
			errors.push({
				field: fields.retypedEmailAddress,
				error: $_('adesa-id-email.email-match-error'),
			});
		}
	}
</script>

<div>
	<TitledElement
		title={$_('adesa-id-email.welcome', { values: { firstName: $repInfo?.firstName } })}
	>
		<div class="flex flex-col p-6" slot="content">
			<div class="flex flex-col mb-8 text-left text-muted">
				{$_('adesa-id-email.this-email-will')}
				<ul class="list-disc list-inside ml-2 leading-relaxed mt-1">
					<li>{$_('adesa-id-email.list-item-1')}</li>
					<li>{$_('adesa-id-email.list-item-2')}</li>
					<li>{$_('adesa-id-email.list-item-3')}</li>
				</ul>
			</div>

			<div class="flex mb-6">
				<div class="flex flex-col mr-6 w-full">
					<Input
						showError={!!errors.find((f) => f.field === fields.emailAddress)}
						errorText={errors.find((f) => f.field === fields.emailAddress)?.error}
						labelText={$_('adesa-id-email.email-label')}
						placeholderText={$_('adesa-id-email.email-placeholder')}
						fieldName={fields.emailAddress}
						bind:value={email.emailAddress}
					/>
				</div>
			</div>
			<div class="flex mb-8">
				<div class="flex flex-col mr-6 w-full">
					<Input
						showError={!!errors.find((f) => f.field === fields.retypedEmailAddress)}
						errorText={errors.find((f) => f.field === fields.retypedEmailAddress)?.error}
						labelText={$_('adesa-id-email.retype-email-label')}
						placeholderText={$_('adesa-id-email.email-placeholder')}
						fieldName={fields.retypedEmailAddress}
						bind:value={email.retypedEmailAddress}
					/>
				</div>
			</div>
			<div class="flex">
				<div class="flex mr-auto">
					<Button
						inverse
						text={$_('shared.back-button-text')}
						dataTest="back-button"
						on:click={() => previousStep()}
					/>
				</div>
				<div class="flex ml-auto">
					<Button
						text={$_('shared.continue-button-text')}
						dataTest="continue-button"
						icon={loading ? 'loading-spinner' : undefined}
						on:click={() => nextStep()}
					/>
				</div>
			</div>
		</div>
	</TitledElement>
</div>
